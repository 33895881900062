import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { Demo } from "./features/demo/Demo";
import { Embed } from "./features/embed/Embed";
import FullscreenPlayer from "./features/player/FullscreenPlayer";
import IFrame from "./features/player/IFrame";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/demo/:token">
            <QueryParamProvider>
              <Demo />
            </QueryParamProvider>
          </Route>
          <Route path="/embed/:embedId">
            <QueryParamProvider ReactRouterRoute={Route}>
              <Embed />
            </QueryParamProvider>
          </Route>
          <Route path="/fullscreen/:embedId">
            <QueryParamProvider>
              <FullscreenPlayer />
            </QueryParamProvider>
          </Route>
          <Route path="/iframe/:embedId">
            <QueryParamProvider>
              <IFrame />
            </QueryParamProvider>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
