import { ServiceBase } from "./ServiceBase";

export class LatencyTester extends ServiceBase {
  TestStartTimeMs = null;
  UEReceiptTimeMs = null;
  UEPreCaptureTimeMs = null;
  UEPostCaptureTimeMs = null;
  UEPreEncodeTimeMs = null;
  UEPostEncodeTimeMs = null;
  UETransmissionTimeMs = null;
  BrowserReceiptTimeMs = null;
  FrameDisplayDeltaTimeMs = null;

  reset = () => {
    this.TestStartTimeMs = null;
    this.UEReceiptTimeMs = null;
    this.UEPreCaptureTimeMs = null;
    this.UEPostCaptureTimeMs = null;
    this.UEPreEncodeTimeMs = null;
    this.UEPostEncodeTimeMs = null;
    this.UETransmissionTimeMs = null;
    this.BrowserReceiptTimeMs = null;
    this.FrameDisplayDeltaTimeMs = null;
  };

  setUETimings = (UETimings) => {
    this.UEReceiptTimeMs = UETimings.ReceiptTimeMs;
    this.UEPreCaptureTimeMs = UETimings.PreCaptureTimeMs;
    this.UEPostCaptureTimeMs = UETimings.PostCaptureTimeMs;
    this.UEPreEncodeTimeMs = UETimings.PreEncodeTimeMs;
    this.UEPostEncodeTimeMs = UETimings.PostEncodeTimeMs;
    this.UETransmissionTimeMs = UETimings.TransmissionTimeMs;
    this.BrowserReceiptTimeMs = Date.now();
    this.onAllLatencyTimingsReady();
  };

  setFrameDisplayDeltaTime = (DeltaTimeMs) => {
    if (this.FrameDisplayDeltaTimeMs == null) {
      this.FrameDisplayDeltaTimeMs = Math.round(DeltaTimeMs);
      this.onAllLatencyTimingsReady();
    }
  };

  onAllLatencyTimingsReady = () => {
    if (!this.BrowserReceiptTimeMs) {
      return;
    }

    let latencyExcludingDecode =
      this.BrowserReceiptTimeMs - this.TestStartTimeMs;
    let uePixelStreamLatency =
      this.UEPreEncodeTimeMs === 0 || this.UEPreCaptureTimeMs === 0
        ? "???"
        : this.UEPostEncodeTimeMs - this.UEPreCaptureTimeMs;
    let captureLatency = this.UEPostCaptureTimeMs - this.UEPreCaptureTimeMs;
    let encodeLatency = this.UEPostEncodeTimeMs - this.UEPreEncodeTimeMs;
    let ueLatency = this.UETransmissionTimeMs - this.UEReceiptTimeMs;
    let networkLatency = latencyExcludingDecode - ueLatency;
    let browserSendLatency =
      latencyExcludingDecode - networkLatency - ueLatency;

    //these ones depend on FrameDisplayDeltaTimeMs
    let endToEndLatency = null;
    let browserSideLatency = null;

    if (this.FrameDisplayDeltaTimeMs && this.BrowserReceiptTimeMs) {
      endToEndLatency = this.FrameDisplayDeltaTimeMs + latencyExcludingDecode;
      browserSideLatency = endToEndLatency - networkLatency - ueLatency;
    }

    const stats = {
      captureLatency,
      encodeLatency,
      uePixelStreamLatency,
      ueLatency,
      networkLatency,
      latencyExcludingDecode,
      endToEndLatency,
      browserSendLatency,
      browserSideLatency,
    };

    this.callEventListeners("latencyStats", stats);
  };

  startLatencyTest = () => {
    this.reset();
    this.TestStartTimeMs = Date.now();
  };
}
