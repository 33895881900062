import React, { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import {
  browserName,
  browserVersion,
  deviceType,
  fullBrowserVersion,
  osName,
  osVersion,
} from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";
import ErrorMessage from "../../components/ErrorMessage";
import { getItem, localStorageSupported } from "../../services/Storage";
import { selectDemoInfo, selectErrorMessage, actions } from "./demoSlice";
import copy from "copy-to-clipboard";

export function Demo(props) {
  const demoInfo = useSelector(selectDemoInfo);
  const { t } = useTranslation();
  const errorMessage = useSelector(selectErrorMessage);
  const dispatch = useDispatch();
  const params = useParams();
  const [streamerInfo, setStreamerInfo] = useState(null);
  const [queryParams] = useQueryParams({
    enableTracking: StringParam,
  });
  const [showDebugInfo, setShowDebugInfo] = useState(false);

  useEffect(() => {
    window.addEventListener("message", onMessageReceived);
  });

  const onMessageReceived = useCallback((event) => {
    if (event.data?.type === "streamerConnected") {
      setStreamerInfo(event.data.streamer);
    }
  }, []);

  const getDebugInfo = () => {
    return {
      "Player ID": getPlayerId(),
      "Device type": deviceType === "browser" ? "desktop" : deviceType,
      OS: osName,
      "OS version": osVersion,
      Browser: browserName,
      "Browser version": fullBrowserVersion,
      "Last streamer": streamerInfo
        ? `${streamerInfo?.ip} (${streamerInfo?.id}) ${
            streamerInfo?.isAutoScaled ? "aws" : "gcp"
          }`
        : "Never connected",
    };
  };

  const debugTableRows = () => {
    const info = getDebugInfo();

    return (
      <tbody>
        {Object.keys(info).map((key) => (
          <tr key={key}>
            <td className="pr-4 whitespace-nowrap">{key}</td>
            <td>{info[key]}</td>
          </tr>
        ))}
      </tbody>
    );
  };

  const debugAsText = () => {
    const info = getDebugInfo();
    let output = "";

    Object.keys(info).forEach((key) => {
      console.log(key);
      output += `${key}: ${info[key]}\n`;
    });

    return output;
  };

  const showSupport = () => {
    setShowDebugInfo(true);
    copy(debugAsText());

    const debugInfo = encodeURIComponent(debugAsText());
    const formURL =
      "https://docs.google.com/forms/d/e/1FAIpQLScw6T0uX01SrDTBwcjO_BoiqoVIBDXK9mnSnr_XT6ik8UXMmw/viewform";

    window.open(`${formURL}?entry.753756817=${debugInfo}`, "_blank").focus();
  };

  const getPlayerId = () => {
    if (localStorageSupported()) {
      return getItem("apprendly:playerId");
    }

    return "";
  };

  useEffect(() => {
    if (!demoInfo && params.token) {
      dispatch(actions.fetchDemo(params.token));
    }
  }, [params.token, dispatch, demoInfo]);

  useEffect(() => {
    if (demoInfo?.accessToken) {
      document.title = `Apprend.ly demo: ${demoInfo.name}`;
      window.addApprendlyEmbed(demoInfo.accessToken, {
        enableTracking: queryParams.enableTracking,
      });
    }
  }, [demoInfo, params.token]);

  return (
    <div>
      <div className="container mx-auto max-w-6xl px-10">
        {demoInfo && (
          <h3 className="mt-10 mb-5 text-2xl font-extrabold">
            {demoInfo.name}
          </h3>
        )}
        {demoInfo && (
          <div className="demo-player-wrapper">
            <div
              className="apprendly-embed"
              data-apprendly-embed={demoInfo.accessToken}
            ></div>
          </div>
        )}
        {errorMessage && <ErrorMessage errorCode={errorMessage} />}
      </div>
      <div className="container mx-auto max-w-6xl px-10">
        <h3 className="mt-4 text-l font-medium text-center text-gray">
          {t("player:havingIssues")}{" "}
          <button
            className="border-b-2 hover:text-white hover:border-white border-gray-dark"
            target="_blank"
            rel="noreferrer"
            onClick={showSupport}
          >
            {t("player:sendBugReport")}
          </button>
        </h3>

        {showDebugInfo && (
          <>
            <button
              className="mt-8 mb-2 bg-gray rounded-md text-black px-2 py-1 mx-auto block"
              onClick={() => copy(debugAsText())}
            >
              Copy info
            </button>
            <div className="bg-dusk p-4 rounded-md mt-4 text-white overflow-x-auto">
              <table className="table-auto overflow-scroll w-full">
                {debugTableRows()}
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
