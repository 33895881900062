export const ToClientMessageType = {
  QualityControlOwnership: 0,
  Response: 1,
  Command: 2,
  FreezeFrame: 3,
  UnfreezeFrame: 4,
  VideoEncoderAvgQP: 5,
  LatencyTest: 6,
  InitialSettings: 7,
};

export const ToStreamerMessageType = {
  // Must be kept in sync with PixelStreamingProtocol::EToUE4Msg C++ enum.

  /*
   * Control Messages. Range = 0..49.
   */
  IFrameRequest: 0,
  RequestQualityControl: 1,
  MaxFpsRequest: 2,
  AverageBitrateRequest: 3,
  StartStreaming: 4,
  StopStreaming: 5,
  LatencyTest: 6,
  RequestInitialSettings: 7,

  /*
   * Input Messages. Range = 50..89.
   */

  // Generic Input Messages. Range = 50..59.
  UIInteraction: 50,
  Command: 51,
};

export const ErrorCodes = {
  // Game and embeds
  GameNotFound: 1001,
  EmbedNotFound: 1002,
  EmbedInactive: 1003,
  DemoNotFound: 1004,
  DemoInactive: 1005,
  EmbedNotInDomain: 1006,

  // CoreAPI
  CoreApiConnectionFailed: 2001,
  CoreApiServerError: 2002,
  CoreApiBadRequest: 2003,

  // Signalling and WebSockets
  SignallingConnectError: 3001,
  SignallingDisconnected: 3002,
  NoStreamerAvailable: 3003,

  // WebRTC
  WebRtcConnectRelayFailed: 4001,
  WebRtcDisconnected: 4002,

  // Misc
  UnsupportedBrowser: 5001,
  UserConnectionFailed: 5002,
};

export const AllowedDomains = ["localhost", "127.0.0.1", "play.apprend.ly"];
