import { put } from "redux-saga/effects";
import { createModule } from "saga-slice";
import { ErrorCodes } from "../../helpers/constants";
import { EmbedService } from "../../services/EmbedService";

const embedService = new EmbedService();

export const demoSlice = createModule({
  name: "demo",
  initialState: {
    demoToken: "",
    demoInfo: null,
    isFetching: false,
    hasError: false,
    errorMessage: "",
  },

  reducers: {
    fetchDemo: (state, token) => {
      state.isFetching = true;
    },
    fetchSuccess: (state, gameInfo) => {
      state.isFetching = false;
      state.demoInfo = gameInfo;
    },
    fetchFail: (state, message) => {
      state.hasError = true;
      state.errorMessage = message;
    },
  },

  sagas: (A) => ({
    *[A.fetchDemo]({ payload }) {
      try {
        const result = yield embedService
          .getEmbedDetails(payload)
          .then((result) => result.data);
        yield put(A.fetchSuccess(result));
      } catch (error) {
        let errorMessage;

        if (error.response && error.response.status === 500) {
          errorMessage = ErrorCodes.CoreApiServerError;
        } else if (!error.response || !error.response.status) {
          errorMessage = ErrorCodes.CoreApiConnectionFailed;
        } else {
          errorMessage = ErrorCodes.DemoNotFound;
        }

        yield put(A.fetchFail(errorMessage));
      }
    },
  }),
});

export const selectDemoInfo = (state) => state.demo.demoInfo;
export const selectErrorMessage = (state) => state.demo.errorMessage;

export const { actions } = demoSlice;
export default demoSlice;
