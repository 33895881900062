import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectGame,
  selectErrorMessage,
  actions,
  selectSignallingService,
} from "./playerSlice";

import { useParams, useLocation } from "react-router-dom";
import { useQueryParam, StringParam } from "use-query-params";
import Signalling from "../signalling/Signalling";
import i18n from "../../i18n";
import { checkDownloadSpeed } from "../../helpers/network";
import { useTranslation } from "react-i18next";
import { getCurrentLogLevel, log, LogLevel } from "../../helpers/logger";
import { isMobileOnly } from "react-device-detect";
import {
  isSupportedBrowser,
  supportedBrowsers,
  supportsVibrate,
} from "../../helpers/device";
import * as webRtcTest from "webrtc-test-suite";
import { ErrorCodes } from "../../helpers/constants";
import PropTypes from "prop-types";
import PopupPlayer from "./PopupPlayer";
import ErrorMessage from "../../components/ErrorMessage";
import PlayerWarning from "../../components/PlayerWarning";
import { ApiService } from "../../services/ApiService";
import useStateRef from "react-usestateref";
import * as Sentry from "@sentry/react";
import { getItem } from "../../services/Storage";
import { v4 as uuidv4 } from "uuid";
import qs from "query-string";

const apiService = new ApiService();

const Player = (props) => {
  const { t } = useTranslation();
  const gameInfo = useSelector(selectGame);
  const { search } = useLocation();
  const errorMessage = useSelector(selectErrorMessage);
  const signallingService = useSelector(selectSignallingService);
  const [hasDisconnected, setHasDisconnected, hasDisconnectedRef] =
    useStateRef(false);
  const [hasPressedPlay, setHasPressedPlay, hasPressedPlayRef] =
    useStateRef(false);
  const [signallingTested, setSignallingTested] = useState(false);
  const [allowFullscreen] = useQueryParam("allowFullscreen", StringParam);
  const [loadedFrom] = useQueryParam("load", StringParam);
  const [slowConnection, setSlowConnection] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const searchTerms = qs.parse(search);

  /*
   * Check local storage if playerId is set otherwise
   * Check if playerId is coming as a prop from Embed or FullScreen (fetched from query params) otherwise.
   * Generate a random value.
   */
  let playerId = props.playerId || getItem("apprendly:playerId");

  if (!playerId || playerId.length === 0) {
    playerId = uuidv4();
  }

  /**
   * Send a message to the embed's owner window.
   */
  const sendEmbedMessage = useCallback(
    (type, data = {}) => {
      data = { apprendly: true, id: props.embedId, type, ...data };

      if (type === "error") {
        try {
          throw new Error(JSON.stringify(data));
        } catch (e) {
          Sentry.captureException(e);
        }
      }

      if ((!props.isFullscreen || props.isFullPage) && window.parent) {
        /*  Check if player was loaded directly from an iframe.
         *  In this case send a message to window -> embed.js and parent to propagate events.
         */
        if (searchTerms.loadedFrom && searchTerms.loadedFrom === "iframe") {
          window.postMessage(data, "*");
          window.parent.postMessage(data, "*");
        } else {
          window.parent.postMessage(data, "*");
        }
      } else if (props.isFullscreen && window.opener) {
        /* When opener is set we are on mobile. Fullscreen component is used. */
        /* This double event messaging is needed on iframe load. On the rest of the loads it will send a double playthough update. */
        window.opener?.parent?.postMessage(data, "*");
        window.postMessage(data, "*");
      }
    },
    [props.embedId, props.isFullscreen, searchTerms]
  );

  /**
   * Game info is received from props. Use to set the game info for the player.
   */
  useEffect(() => {
    if (props.gameInfo) {
      dispatch(actions.setGameInfo(props.gameInfo));
    }
  }, [dispatch, props.gameInfo]);

  /**
   * When params are ready, fetch game info from db, unless passed from props already.
   */
  useEffect(() => {
    if (!gameInfo && (props.gameId || params.id) && !props.gameInfo) {
      dispatch(actions.fetchGame(props.gameId || params.id));
    }
  }, [params.id, props.gameId, dispatch, gameInfo, props.gameInfo]);

  /**
   * Change UI language based on game settings.
   */
  useEffect(() => {
    if (gameInfo && gameInfo.contents?.Language) {
      i18n.changeLanguage(gameInfo.contents.Language);
    }
  }, [gameInfo]);

  /**
   * Set player ID based on props, so it can be used elsewhere in the player.
   */
  useEffect(() => {
    dispatch(actions.setPlayerId(playerId));
  }, [playerId, dispatch]);

  /**
   * If receiving an error related to signalling connection, set up automatic reconnecting.
   */
  useEffect(() => {
    const disconnectedErrors = [
      ErrorCodes.SignallingDisconnected,
      ErrorCodes.SignallingConnectError,
      ErrorCodes.UserConnectionFailed,
    ];

    if (signallingService && disconnectedErrors.includes(errorMessage)) {
      signallingService.setEventListener("reconnect", () => {
        log(LogLevel.debug, "Reconnected to signalling", errorMessage);

        sendEmbedMessage("reconnected");
        setHasDisconnected(false);
        dispatch(actions.setError(null));
      });
    }
  }, [
    errorMessage,
    dispatch,
    signallingService,
    sendEmbedMessage,
    setHasDisconnected,
  ]);

  /**
   * When an error occurs, send info to embed if of a relevant type.
   */
  useEffect(() => {
    if (errorMessage?.length === 0) {
      return;
    }

    const connectionFailed = [
      ErrorCodes.SignallingConnectError,
      ErrorCodes.WebRtcConnectRelayFailed,
      ErrorCodes.CoreApiConnectionFailed,
    ];
    const disconnected = [
      ErrorCodes.SignallingDisconnected,
      ErrorCodes.WebRtcDisconnected,
      ErrorCodes.UserConnectionFailed,
    ];

    log(
      LogLevel.debug,
      "Error sending",
      errorMessage,
      connectionFailed.includes(errorMessage),
      disconnected.includes(errorMessage)
    );

    if (connectionFailed.includes(errorMessage)) {
      sendEmbedMessage("error", {
        errorType: "connectionFailed",
        errorCode: errorMessage,
      });
    } else if (disconnected.includes(errorMessage)) {
      sendEmbedMessage("error", {
        errorType: "disconnected",
        errorCode: errorMessage,
      });
    }
  }, [errorMessage, sendEmbedMessage]);

  /**
   * Check if the signalling server is up, according to CoreAPI.
   *
   * @returns {boolean} True if PXS is possibly or confirmed up, false if confirmed down.
   */
  const checkPxsStatus = async () => {
    try {
      const result = await apiService.apiRequestGet("system/status");
      return result.data.pxsStatus;
    } catch (error) {
      return true;
    }
  };

  /**
   * Set up connection to signalling, and test WebRTC connection.
   */
  useEffect(() => {
    if (gameInfo && !signallingService.initiated) {
      sendEmbedMessage("playerLoaded");

      signallingService.init();

      // Test WebRTC capabilities (if not already in fullscreen, where it's been tested)
      if (!props.isFullscreen && process.env.ENABLE_WEBRTC_TEST) {
        signallingService.addEventListener("connect", async () => {
          const config = await signallingService.getWebRtcConfig();
          log(LogLevel.debug, "Received WebRTC config", config);

          try {
            const webRtcTestResult = await webRtcTest.checkPeerConnection(
              {
                ...config,
                timeout: 15000,
                iceTransportPolicy: "relay",
              },
              getCurrentLogLevel() === LogLevel.debug,
              15000
            );
            log(LogLevel.debug, "Got WebRTC test result", webRtcTestResult);
            setSignallingTested(true);
            sendEmbedMessage("playerReady");
          } catch (error) {
            log(LogLevel.error, "Failed to connect to WebRTC test", error);
            dispatch(actions.setError(ErrorCodes.WebRtcConnectRelayFailed));
          }
        });
      } else {
        log(LogLevel.debug, "Skipping WebRTC test");
        setSignallingTested(true);
        sendEmbedMessage("playerReady");
      }

      signallingService.setEventListener("connect_error", async (data) => {
        if (hasDisconnectedRef.current) {
          return;
        }

        const pxsUp = await checkPxsStatus();

        const errorCode = pxsUp
          ? ErrorCodes.UserConnectionFailed
          : ErrorCodes.SignallingConnectError;

        log(LogLevel.error, "Failed to connect to signalling.", data);
        dispatch(actions.setError(errorCode));
      });

      signallingService.setEventListener("disconnect", async (data) => {
        const pxsUp = await checkPxsStatus();

        const errorCode = pxsUp
          ? ErrorCodes.UserConnectionFailed
          : ErrorCodes.SignallingDisconnected;

        setHasDisconnected(true);
        log(LogLevel.error, "Disconnected from signalling.", data);
        dispatch(actions.setError(errorCode));
      });
    }
  }, [
    signallingService,
    gameInfo,
    dispatch,
    props.isFullscreen,
    sendEmbedMessage,
    hasDisconnectedRef,
    setHasDisconnected,
  ]);

  /**
   * Callback when receiving a window message.
   */
  const onWindowMessage = useCallback(
    (event) => {
      // Messages from a popup player
      if (!props.isFullscreen && event.origin === window.origin) {
        // Requests from a popup player to receive game info
        if (gameInfo && event.data?.type === "getEmbedInfo") {
          event.source.postMessage({
            type: "embedInfo",
            gameInfo,
            enableTracking: props.enableTracking,
            playerId,
          });
        }
        // General game messages from a popup player. Forwards so that embed players etc. can receive them.
        // else if (event.data?.apprendly && window.parent) {
        //   window.parent.postMessage(event.data, "*");
        // }
      }
    },
    [gameInfo, props.isFullscreen, props.enableTracking, playerId]
  );

  /**
   * Set up listeners for window messages.
   */
  useEffect(() => {
    if (gameInfo) {
      window.addEventListener("message", onWindowMessage);
    }
  }, [gameInfo, onWindowMessage]);

  /**
   * Initiate connection speed testing.
   */
  useEffect(() => {
    if (!props.isFullscreen) {
      const checkSpeed = async () => {
        const speed = await checkDownloadSpeed(3, 350);
        log(LogLevel.debug, "Network download speed test", speed);

        if (!speed.result) {
          setTimeout(() => {
            setSlowConnection(true);
          }, 3000);
        }
      };

      setTimeout(() => {
        if (!hasPressedPlayRef.current) {
          checkSpeed();
        }
      }, 2000);
    }
  }, [props.isFullscreen]);

  /**
   * Callback when user quits the game (from inside game stream).
   */
  const onQuitGame = () => {
    sendEmbedMessage("quitGame");

    if (props.isFullscreen) {
      window.close();
    }
  };

  /**
   * Callback when receiving messages from the WebRTC player with info like game events
   * or player changes.
   *
   * @param {any} data
   */
  const onGameMessage = (data) => {
    sendEmbedMessage("gameEvent", data);

    log(LogLevel.debug, "Received game message", data);

    // Small hack: if a value called VibratePhone is set to 1, try to vibrate (works only on Android)
    if (
      data.type === "gameEvent" &&
      data.eventName === "valueChanged" &&
      data.data.valueName === "VibratePhone"
    ) {
      let pattern = data.data.value.trim().split(",");

      if (pattern.length > 0 && pattern[0]) {
        pattern = pattern.map((v) => parseFloat(v.trim()) * 1000);

        log(
          LogLevel.debug,
          "Vibration message received",
          "Supports vibration: ",
          supportsVibrate(),
          pattern
        );

        if (supportsVibrate()) {
          navigator.vibrate(pattern);
        }
      }
    }
  };

  /**
   * Callback when the user has pressed "Play", so the game has started loading.
   */
  const onStartLoading = () => {
    sendEmbedMessage("gameMessage", { eventName: "startedLoading" });
    setHasPressedPlay(true);

    if (props.onStartLoading) {
      props.onStartLoading();
    }
  };

  return (
    <div
      className="player-wrapper bg-dusk-dark w-screen h-screen flex align-middle justify-center relative"
      style={props.style}
    >
      {(props.isFullscreen || signallingTested) && !errorMessage && (
        <>
          {(!isMobileOnly || props.isFullscreen) && gameInfo && (
            <Signalling
              gameId={gameInfo.id}
              gameName={gameInfo.name}
              askForPlayerName={gameInfo.contents.RequirePlayerName}
              embedId={props.embedId}
              playerId={playerId}
              allowFullscreen={allowFullscreen !== "false" && !isMobileOnly}
              onGameMessage={onGameMessage}
              autostart={props.autostart}
              onQuitGame={onQuitGame}
              onStartLoading={onStartLoading}
              onStartPlaying={props.onStartPlaying}
              enableTracking={props.enableTracking}
            />
          )}
          {isMobileOnly && !props.isFullscreen && gameInfo && (
            <PopupPlayer gameId={gameInfo.id} embedId={props.embedId} />
          )}
          {!isSupportedBrowser() && (
            <PlayerWarning
              isForeground={true}
              removable={true}
              rememberRemove={true}
              warningName="unsupported-browser"
            >
              <h2 className="text-xs mb-2 sm:text-2xl">
                {t("player:unsupportedBrowser")}
              </h2>
              <h3 className="text-xs sm:text-xl">
                {t("player:supportedBrowsers", {
                  replace: { browserList: supportedBrowsers.join(", ") },
                })}
              </h3>
            </PlayerWarning>
          )}
          {slowConnection && !hasPressedPlay && (
            <PlayerWarning
              isForeground={true}
              removable={true}
              warningName="slow-connection"
            >
              <h2 className="text-xs mb-5 sm:text-2xl">
                {t("player:slowConnectionHeader")}
              </h2>
              <h3 className="text-xs sm:text-xl">
                {t("player:slowConnectionDescription")}
              </h3>
            </PlayerWarning>
          )}
        </>
      )}
      {!signallingTested && !props.isFullscreen && !errorMessage && (
        <div className="status-text">{t("player:settingUpConnection")}</div>
      )}
      {errorMessage && <ErrorMessage errorCode={errorMessage} />}
    </div>
  );
};

Player.propTypes = {
  gameId: PropTypes.string.isRequired,
  embedId: PropTypes.string.isRequired,
  playerId: PropTypes.string,
  gameInfo: PropTypes.object,
  isFullscreen: PropTypes.bool,
  isFullPage: PropTypes.bool,
  autostart: PropTypes.bool,
  onStartPlaying: PropTypes.func,
  onStartLoading: PropTypes.func,
  enableTracking: PropTypes.bool,
};

export default Player;
