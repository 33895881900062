export const MessageType = {
  // Keyboard Input Message. Range = 60..69.
  KeyDown: 60,
  KeyUp: 61,
  KeyPress: 62,

  // Mouse Input Messages. Range = 70..79.
  MouseEnter: 70,
  MouseLeave: 71,
  MouseDown: 72,
  MouseUp: 73,
  MouseMove: 74,
  MouseWheel: 75,

  // Touch Input Messages. Range = 80..89.
  TouchStart: 80,
  TouchEnd: 81,
  TouchMove: 82,
};

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
export const MouseButton = {
  MainButton: 0, // Left button.
  AuxiliaryButton: 1, // Wheel button.
  SecondaryButton: 2, // Right button.
  FourthButton: 3, // Browser Back button.
  FifthButton: 4, // Browser Forward button.
};

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/buttons
export const MouseButtonsMask = {
  PrimaryButton: 1, // Left button.
  SecondaryButton: 2, // Right button.
  AuxiliaryButton: 4, // Wheel button.
  FourthButton: 8, // Browser Back button.
  FifthButton: 16, // Browser Forward button.
};

// Must be kept in sync with JavaScriptKeyCodeToFKey C++ array. The index of the
// entry in the array is the special key code given below.
export const SpecialKeyCodes = {
  BackSpace: 8,
  Shift: 16,
  Control: 17,
  Alt: 18,
  RightShift: 253,
  RightControl: 254,
  RightAlt: 255,
};

export const controlTypes = {
  locked: 1,
  unlocked: 2,
};

export const disallowedKeys = [
  "f", // Used for fullscreen from JS, don't send to UE
  "F11", // Triggers fullscreen change in UE, we want this for ourselves
];

export class UEController {
  playerEl = null;
  connection = null;

  normalizeAndQuantizeUnsigned = null;
  normalizeAndQuantizeSigned = null;
  printDebug = false;
  mouseLocked = false;

  playerRes = {};

  inputOptions = {
    // Browser keys are those which are typically used by the browser UI. We
    // usually want to suppress these to allow, for example, UE4 to show shader
    // complexity with the F5 key without the web page refreshing.
    suppressBrowserKeys: true,

    // UE4 has a faketouches option which fakes a single finger touch when the
    // user drags with their mouse. We may perform the reverse; a single finger
    // touch may be converted into a mouse drag UE4 side. This allows a
    // non-touch application to be controlled partially via a touch device.
    fakeMouseWithTouches: true,
  };

  controlType = controlTypes.locked;

  playerAspectRatio = 0;
  videoAspectRatio = 0;

  /**
   * Constructor.
   *
   * @param {UEConnectionService} connection
   * @param {*} playerEl
   */
  constructor(connection, playerEl) {
    this.connection = connection;
    this.playerEl = playerEl;
  }

  /**
   * Send input data to the UE connection.
   *
   * @param {Buffer} buffer
   */
  sendInputData = (buffer) => {
    this.connection.sendInputData(buffer);
  };

  /**
   * Normalize and quantize unsigned input.
   *
   * @param {*} x
   * @param {*} y
   * @returns
   */
  normalizeAndQuantizeUnsigned = (x, y) => {
    if (this.playerAspectRatio > this.videoAspectRatio) {
      const ratio = this.playerAspectRatio / this.videoAspectRatio;

      let normalizedX = x / this.playerRes.width;
      let normalizedY = ratio * (y / this.playerRes.height - 0.5) + 0.5;

      if (
        normalizedX < 0.0 ||
        normalizedX > 1.0 ||
        normalizedY < 0.0 ||
        normalizedY > 1.0
      ) {
        return {
          inRange: false,
          x: 65535,
          y: 65535,
        };
      } else {
        return {
          inRange: true,
          x: normalizedX * 65536,
          y: normalizedY * 65536,
        };
      }
    } else {
      const ratio = this.videoAspectRatio / this.playerAspectRatio;

      let normalizedX = ratio * (x / this.playerRes.width - 0.5) + 0.5;
      let normalizedY = y / this.playerRes.height;
      if (
        normalizedX < 0.0 ||
        normalizedX > 1.0 ||
        normalizedY < 0.0 ||
        normalizedY > 1.0
      ) {
        return {
          inRange: false,
          x: 65535,
          y: 65535,
        };
      } else {
        return {
          inRange: true,
          x: normalizedX * 65536,
          y: normalizedY * 65536,
        };
      }
    }
  };

  unquantizeAndDenormalizeUnsigned = (x, y) => {
    let normalizedX, normalizedY;

    if (this.playerAspectRatio > this.videoAspectRatio) {
      const ratio = this.playerAspectRatio / this.videoAspectRatio;

      normalizedX = x / 65536;
      normalizedY = (y / 65536 - 0.5) / ratio + 0.5;
    } else {
      const ratio = this.videoAspectRatio / this.playerAspectRatio;

      normalizedX = (x / 65536 - 0.5) / ratio + 0.5;
      normalizedY = y / 65536;
    }

    return {
      x: normalizedX * this.playerRes.width,
      y: normalizedY * this.playerRes.height,
    };
  };

  normalizeAndQuantizeSigned = (x, y) => {
    let normalizedX, normalizedY;

    if (this.playerAspectRatio > this.videoAspectRatio) {
      const ratio = this.playerAspectRatio / this.videoAspectRatio;
      normalizedX = (ratio * x) / (0.5 * this.playerRes.width);
      normalizedY = y / (0.5 * this.playerRes.height);
    } else {
      const ratio = this.videoAspectRatio / this.playerAspectRatio;
      normalizedX = (ratio * x) / (0.5 * this.playerRes.width);
      normalizedY = y / (0.5 * this.playerRes.height);
    }

    return {
      x: normalizedX * 32767,
      y: normalizedY * 32767,
    };
  };

  /**
   * Set up the functions for normalizing and quantizing mouse input from a player to be sent to UE.
   *
   * @param {object} videoRes Object with width and height of the video stream from UE.
   * @param {object} playerRes Object with width and height of the video player used on the website/app.
   */
  setupNormalizeAndQuantize = (videoRes, playerRes) => {
    this.playerRes = playerRes;

    const playerWidth = this.playerRes.width;
    const playerHeight = this.playerRes.height;

    this.playerAspectRatio = playerHeight / playerWidth;
    this.videoAspectRatio = videoRes.height / videoRes.width;

    if (this.printDebug) {
      console.log(
        "Setting up normalize. Ratios: ",
        this.playerAspectRatio,
        this.videoAspectRatio,
        videoRes,
        playerRes
      );
    }
  };

  emitMouseMove = (x, y, deltaX, deltaY) => {
    let coord = this.normalizeAndQuantizeUnsigned(x, y);
    let delta = this.normalizeAndQuantizeSigned(deltaX, deltaY);

    if (this.printDebug) {
      console.log(
        `x: ${x}, y:${y}, dX: ${deltaX}, dY: ${deltaY}`,
        coord,
        delta
      );
    }

    let Data = new DataView(new ArrayBuffer(9));
    Data.setUint8(0, MessageType.MouseMove);
    Data.setUint16(1, coord.x, true);
    Data.setUint16(3, coord.y, true);
    Data.setInt16(5, delta.x, true);
    Data.setInt16(7, delta.y, true);
    this.sendInputData(Data.buffer);
  };

  emitMouseDown = (button, x, y) => {
    if (this.printDebug) {
      console.log(`mouse button ${button} down at (${x}, ${y})`);
    }

    let coord = this.normalizeAndQuantizeUnsigned(x, y);
    let Data = new DataView(new ArrayBuffer(6));
    Data.setUint8(0, MessageType.MouseDown);
    Data.setUint8(1, button);
    Data.setUint16(2, coord.x, true);
    Data.setUint16(4, coord.y, true);
    this.sendInputData(Data.buffer);
  };

  emitMouseUp = (button, x, y) => {
    if (this.printDebug) {
      console.log(`mouse button ${button} up at (${x}, ${y})`);
    }

    let coord = this.normalizeAndQuantizeUnsigned(x, y);
    let Data = new DataView(new ArrayBuffer(6));
    Data.setUint8(0, MessageType.MouseUp);
    Data.setUint8(1, button);
    Data.setUint16(2, coord.x, true);
    Data.setUint16(4, coord.y, true);
    this.sendInputData(Data.buffer);
  };

  emitMouseWheel = (delta, x, y) => {
    if (this.printDebug) {
      console.log(`mouse wheel with delta ${delta} at (${x}, ${y})`);
    }

    let coord = this.normalizeAndQuantizeUnsigned(x, y);
    let Data = new DataView(new ArrayBuffer(7));
    Data.setUint8(0, MessageType.MouseWheel);
    Data.setInt16(1, delta, true);
    Data.setUint16(3, coord.x, true);
    Data.setUint16(5, coord.y, true);
    this.sendInputData(Data.buffer);
  };

  // If the user has any mouse buttons pressed then release them.
  releaseMouseButtons = (buttons, x, y) => {
    if (buttons & MouseButtonsMask.PrimaryButton) {
      this.emitMouseUp(MouseButton.MainButton, x, y);
    }
    if (buttons & MouseButtonsMask.SecondaryButton) {
      this.emitMouseUp(MouseButton.SecondaryButton, x, y);
    }
    if (buttons & MouseButtonsMask.AuxiliaryButton) {
      this.emitMouseUp(MouseButton.AuxiliaryButton, x, y);
    }
    if (buttons & MouseButtonsMask.FourthButton) {
      this.emitMouseUp(MouseButton.FourthButton, x, y);
    }
    if (buttons & MouseButtonsMask.FifthButton) {
      this.emitMouseUp(MouseButton.FifthButton, x, y);
    }
  };

  // If the user has any mouse buttons pressed then press them again.
  pressMouseButtons = (buttons, x, y) => {
    if (buttons & MouseButtonsMask.PrimaryButton) {
      this.emitMouseDown(MouseButton.MainButton, x, y);
    }
    if (buttons & MouseButtonsMask.SecondaryButton) {
      this.emitMouseDown(MouseButton.SecondaryButton, x, y);
    }
    if (buttons & MouseButtonsMask.AuxiliaryButton) {
      this.emitMouseDown(MouseButton.AuxiliaryButton, x, y);
    }
    if (buttons & MouseButtonsMask.FourthButton) {
      this.emitMouseDown(MouseButton.FourthButton, x, y);
    }
    if (buttons & MouseButtonsMask.FifthButton) {
      this.emitMouseDown(MouseButton.FifthButton, x, y);
    }
  };

  inputCallbacks = {
    getXYValues: (e) => {
      let x = e.offsetX,
        y = e.offsetY;

      if (this.controlType === controlTypes.locked) {
        x = this.playerRes.width / 2;
        y = this.playerRes.height / 2;
      }

      return { x, y };
    },

    doCallback: (e, callback) => {
      if (this.controlType === controlTypes.locked && !this.mouseLocked) {
        return;
      }

      const { x, y } = this.inputCallbacks.getXYValues(e);

      callback(x, y);
    },

    updateMousePosition: (e) => {
      this.inputCallbacks.doCallback(e, (x, y) => {
        x += e.movementX;
        y += e.movementY;

        // // Constrain mouse movement to inside the player frame, instead of moving to the left when disappearing out on the right side, etc.
        // // This way is more like normal mouse movement inside a screen.
        if (x >= this.playerRes.width - 1) {
          x = this.playerRes.width - 1;
        }
        if (y >= this.playerRes.height - 1) {
          y = this.playerRes.height - 1;
        }
        if (x <= 1) {
          x = 1;
        }
        if (y <= 1) {
          y = 1;
        }

        this.emitMouseMove(x, y, e.movementX, e.movementY);
        e.preventDefault();
      });
    },

    onMouseDown: (e) => {
      this.inputCallbacks.doCallback(e, (x, y) => {
        this.emitMouseDown(e.button, x, y);
      });
    },

    onMouseUp: (e) => {
      this.inputCallbacks.doCallback(e, (x, y) => {
        this.emitMouseUp(e.button, x, y);
      });
    },

    onMouseWheel: (e) => {
      this.inputCallbacks.doCallback(e, (x, y) => {
        const delta = e.wheelDelta !== undefined ? e.wheelDelta : e.deltaY * -1;
        e.preventDefault();
        e.stopImmediatePropagation();
        this.emitMouseWheel(delta, x, y);
        return false;
      });
    },

    onPressMouseButton: (e) => {
      this.inputCallbacks.doCallback(e, (x, y) => {
        this.pressMouseButtons(e.buttons, x, y);
      });
    },

    onReleaseMouseButton: (e) => {
      this.inputCallbacks.doCallback(e, (x, y) => {
        this.releaseMouseButtons(e.buttons, x, y);
      });
    },
  };

  lockStateChange = () => {
    this.playerEl.requestPointerLock =
      this.playerEl.requestPointerLock || this.playerEl.mozRequestPointerLock;

    document.exitPointerLock =
      document.exitPointerLock || document.mozExitPointerLock;

    if (
      document.pointerLockElement === this.playerEl ||
      document.mozPointerLockElement === this.playerEl
    ) {
      if (!this.mouseLocked) {
        document.addEventListener(
          "mousemove",
          this.inputCallbacks.updateMousePosition,
          false
        );
        this.mouseLocked = true;
      }
    } else {
      console.log("The pointer lock status is now unlocked");
      this.mouseLocked = false;

      document.removeEventListener(
        "mousemove",
        this.inputCallbacks.updateMousePosition,
        false
      );
    }
  };

  /**
   * Register mouse events with the cursor locked into the video player.
   */
  registerLockedMouseEvents = () => {
    this.controlType = controlTypes.locked;

    this.playerEl.onclick = () => {
      this.playerEl.requestPointerLock();
    };

    // Respond to lock state change events
    // document.removeEventListener("pointerlockchange", this.lockStateChange);
    // document.removeEventListener("mozpointerlockchange", this.lockStateChange);
    document.onpointerlockchange = this.lockStateChange;
    document.onmozpointerlockchange = this.lockStateChange;

    this.playerEl.onmousedown = this.inputCallbacks.onMouseDown;
    this.playerEl.onmouseup = this.inputCallbacks.onMouseUp;
    this.playerEl.onwheel = this.inputCallbacks.onMouseWheel;
    this.playerEl.pressMouseButtons = this.inputCallbacks.onPressMouseButton;
    this.playerEl.releaseMouseButtons =
      this.inputCallbacks.onReleaseMouseButton;
  };

  /**
   * Register mouse events for when the mouse is visible in the browser and not locked into the video player.
   */
  registerHoveringMouseEvents = () => {
    this.controlType = controlTypes.unlocked;

    this.playerEl.onmousemove = this.inputCallbacks.updateMousePosition;
    this.playerEl.onmousedown = this.inputCallbacks.onMouseDown;
    this.playerEl.onmouseup = this.inputCallbacks.onMouseUp;
    this.playerEl.onwheel = this.inputCallbacks.onMouseWheel;
    this.playerEl.pressMouseButtons = this.inputCallbacks.onPressMouseButton;
    this.playerEl.releaseMouseButtons =
      this.inputCallbacks.onReleaseMouseButton;

    // When the context menu is shown then it is safest to release the button
    // which was pressed when the event happened. This will guarantee we will
    // get at least one mouse up corresponding to a mouse down event. Otherwise
    // the mouse can get stuck.
    // https://github.com/facebook/react/issues/5531
    this.playerEl.oncontextmenu = (e) => {
      this.emitMouseUp(e.button, e.offsetX, e.offsetY);
      e.preventDefault();
    };
  };

  unregisterMouseEvents = () => {};

  // Browser keys do not have a charCode so we only need to test keyCode.
  isKeyCodeBrowserKey = (keyCode) => {
    // Function keys or tab key.
    return (
      // Function keys
      (keyCode >= 112 && keyCode <= 123) ||
      // Arrow keys
      keyCode >= 37 ||
      keyCode <= 40 ||
      keyCode === 9
    );
  };

  // We want to be able to differentiate between left and right versions of some
  // keys.
  getKeyCode = (e) => {
    if (e.keyCode === SpecialKeyCodes.Shift && e.code === "ShiftRight")
      return SpecialKeyCodes.RightShift;
    else if (e.keyCode === SpecialKeyCodes.Control && e.code === "ControlRight")
      return SpecialKeyCodes.RightControl;
    else if (e.keyCode === SpecialKeyCodes.Alt && e.code === "AltRight")
      return SpecialKeyCodes.RightAlt;
    else return e.keyCode;
  };

  registerKeyboardEvents = () => {
    document.onkeydown = (e) => {
      if (this.printDebug) {
        console.log(`key down ${e.keyCode}, repeat = ${e.repeat}`);
      }

      if (disallowedKeys.includes(e.key)) {
        return;
      }

      this.sendInputData(
        new Uint8Array([MessageType.KeyDown, this.getKeyCode(e), e.repeat])
          .buffer
      );

      if (
        this.inputOptions.suppressBrowserKeys &&
        this.isKeyCodeBrowserKey(e.keyCode)
      ) {
        e.preventDefault();
      }
    };

    document.onkeyup = (e) => {
      if (this.printDebug) {
        console.log(`key up ${e.keyCode}`);
      }

      if (disallowedKeys.includes(e.key)) {
        return;
      }

      this.sendInputData(
        new Uint8Array([MessageType.KeyUp, this.getKeyCode(e)]).buffer
      );

      if (
        this.inputOptions.suppressBrowserKeys &&
        this.isKeyCodeBrowserKey(e.keyCode)
      ) {
        e.preventDefault();
      }
    };

    document.addEventListener("keypress", (e) => {
      if (this.printDebug) {
        console.log(`key press ${e.charCode}`);
      }

      if (disallowedKeys.includes(e.key)) {
        return;
      }

      let data = new DataView(new ArrayBuffer(3));
      data.setUint8(0, MessageType.KeyPress);
      data.setUint16(1, e.charCode, true);
      this.sendInputData(data.buffer);
    });
  };

  unregisterKeyboardEvents = () => {
    document.onkeydown = null;
    document.onkeyup = null;
  };

  registerMouseEnterAndLeaveEvents = () => {
    this.playerEl.onmouseenter = (e) => {
      if (this.printDebug) {
        console.log("mouse enter");
      }

      let Data = new DataView(new ArrayBuffer(1));
      Data.setUint8(0, MessageType.MouseEnter);
      this.sendInputData(Data.buffer);
      this.pressMouseButtons(e);
    };

    this.playerEl.onmouseleave = (e) => {
      if (this.printDebug) {
        console.log("mouse leave");
      }

      let Data = new DataView(new ArrayBuffer(1));
      Data.setUint8(0, MessageType.MouseLeave);
      this.sendInputData(Data.buffer);
      this.releaseMouseButtons(e);
    };
  };

  registerTouchEvents = () => {
    // We need to assign a unique identifier to each finger.
    // We do this by mapping each Touch object to the identifier.
    const fingers = [9, 8, 7, 6, 5, 4, 3, 2, 1, 0];
    let fingerIds = {};

    const rememberTouch = (touch) => {
      let finger = fingers.pop();
      if (finger === undefined) {
        console.log("exhausted touch indentifiers");
      }
      fingerIds[touch.identifier] = finger;
    };

    const forgetTouch = (touch) => {
      fingers.push(fingerIds[touch.identifier]);
      delete fingerIds[touch.identifier];
    };

    const emitTouchData = (type, touches) => {
      let data = new DataView(new ArrayBuffer(2 + 6 * touches.length));
      data.setUint8(0, type);
      data.setUint8(1, touches.length);
      let byte = 2;

      for (let t = 0; t < touches.length; t++) {
        let touch = touches[t];
        let x = touch.clientX - this.playerEl.offsetLeft;
        let y = touch.clientY - this.playerEl.offsetTop;

        if (this.printDebug) {
          console.log(`F${fingerIds[touch.identifier]}=(${x}, ${y})`);
        }

        let coord = this.normalizeAndQuantizeUnsigned(x, y);
        data.setUint16(byte, coord.x, true);
        byte += 2;
        data.setUint16(byte, coord.y, true);
        byte += 2;
        data.setUint8(byte, fingerIds[touch.identifier], true);
        byte += 1;
        data.setUint8(byte, 255 * touch.force, true); // force is between 0.0 and 1.0 so quantize into byte.
        byte += 1;
      }

      this.sendInputData(data.buffer);
    };

    if (this.inputOptions.fakeMouseWithTouches) {
      let finger = undefined;

      this.playerEl.ontouchstart = (e) => {
        if (finger === undefined) {
          let firstTouch = e.changedTouches[0];
          finger = {
            id: firstTouch.identifier,
            x: firstTouch.clientX - this.playerRes.left,
            y: firstTouch.clientY - this.playerRes.top,
          };
          // Hack: Mouse events require an enter and leave so we just
          // enter and leave manually with each touch as this event
          // is not fired with a touch device.
          this.playerEl.onmouseenter(e);

          const x = finger.x;
          const y = finger.y;

          this.emitMouseMove(x, y, 0, 0);

          setTimeout(() => {
            this.emitMouseMove(x, y, 0, 0);
            this.emitMouseDown(MouseButton.MainButton, x, y);
          }, 20);
        }
        e.preventDefault();
      };

      this.playerEl.ontouchend = (e) => {
        for (let t = 0; t < e.changedTouches.length; t++) {
          let touch = e.changedTouches[t];
          if (finger && touch.identifier === finger.id) {
            let x = touch.clientX - this.playerRes.left;
            let y = touch.clientY - this.playerRes.top;

            setTimeout(() => {
              this.emitMouseUp(MouseButton.MainButton, x, y);
              // Hack: Manual mouse leave event.
              this.playerEl.onmouseleave(e);
            }, 20);

            finger = undefined;
            break;
          }
        }
        e.preventDefault();
      };

      this.playerEl.ontouchmove = (e) => {
        for (let t = 0; t < e.touches.length; t++) {
          let touch = e.touches[t];
          if (finger && touch.identifier === finger.id) {
            let x = touch.clientX - this.playerRes.left;
            let y = touch.clientY - this.playerRes.top;
            this.emitMouseMove(x, y, x - finger.x, y - finger.y);
            finger.x = x;
            finger.y = y;
            break;
          }
        }
        e.preventDefault();
      };
    } else {
      this.playerEl.ontouchstart = (e) => {
        // Assign a unique identifier to each touch.
        for (let t = 0; t < e.changedTouches.length; t++) {
          rememberTouch(e.changedTouches[t]);
        }

        if (this.printDebug) {
          console.log("touch start");
        }
        emitTouchData(MessageType.TouchStart, e.changedTouches);
        e.preventDefault();
      };

      this.playerEl.ontouchend = (e) => {
        if (this.printDebug) {
          console.log("touch end");
        }
        emitTouchData(MessageType.TouchEnd, e.changedTouches);

        // Re-cycle unique identifiers previously assigned to each touch.
        for (let t = 0; t < e.changedTouches.length; t++) {
          forgetTouch(e.changedTouches[t]);
        }
        e.preventDefault();
      };

      this.playerEl.ontouchmove = (e) => {
        if (this.printDebug) {
          console.log("touch move");
        }
        emitTouchData(MessageType.TouchMove, e.touches);
        e.preventDefault();
      };
    }
  };
}
