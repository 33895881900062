import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectGame, actions } from "./playerSlice";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import Player from "./Player";
import { log, LogLevel } from "../../helpers/logger";
import { supportsFullscreen } from "../../helpers/device";
import FullscreenFeatures from "./FullscreenFeatures";

const FullscreenPlayer = (props) => {
  const { t } = useTranslation();

  const containerRef = useRef();
  const [errorMessage, setErrorMessage] = useState(null);
  const [embedInfo, setEmbedInfo] = useState(null);
  const [setupFullscreen, setSetupFullscreen] = useState(false);
  const gameInfo = useSelector(selectGame);
  const dispatch = useDispatch();
  const params = useParams();
  const [isScrolledDown, setIsScrolledDown] = useState(false);

  const onScroll = useCallback(() => {
    if (supportsFullscreen()) {
      return;
    }

    const scrolledDown = window.scrollY >= window.innerHeight / 2 + 100;
    setIsScrolledDown(scrolledDown);
  }, []);

  useEffect(() => {
    if (!supportsFullscreen()) {
      window.addEventListener("scroll", onScroll);
      window.addEventListener("orientationchange", () => {
        window.scrollTo(0, 0);
      });
    } else {
      setIsScrolledDown(true);
    }
  }, [onScroll]);

  /**
   * Send a message to the embed's owner window.
   */
  const sendEmbedMessage = useCallback(
    (type, data = {}) => {
      data = { apprendly: true, id: props.embedId, type, ...data };

      if (window.opener) {
        window.opener.postMessage(data, window.location.origin);
      }
    },
    [props.embedId]
  );

  useEffect(() => {
    window.apprendly.initialize();
  }, []);

  const onMessageReceived = useCallback(
    (event) => {
      if (event.data?.type === "embedInfo" && !gameInfo) {
        log(LogLevel.debug, "Received embed info", event.data);
        dispatch(actions.setGameInfo(event.data.gameInfo));
        dispatch(actions.setPlayerId(event.data.playerId));
        setEmbedInfo(event.data);
      }
    },
    [gameInfo, dispatch]
  );

  useEffect(() => {
    if (!gameInfo && params.embedId && window.opener) {
      log(LogLevel.debug, "Requesting game info");

      window.opener.postMessage(
        {
          type: "getEmbedInfo",
          embedId: params.embedId,
        },
        window.location.origin
      );
      window.addEventListener("message", onMessageReceived);
    } else if (!window.opener) {
      setErrorMessage(t("player:couldNotConnectServer"));
    }
  }, [params.embedId, gameInfo, onMessageReceived, t]);

  useEffect(() => {
    if (gameInfo?.name) {
      document.title = gameInfo.name;
    }
  }, [gameInfo]);

  const quitGame = () => {
    sendEmbedMessage("quitGame");
    window.close();
  };

  return (
    <div ref={containerRef} className="apprendly-embed">
      {!supportsFullscreen() && (
        <div className="w-screen h-screen bg-dusk flex flex-col justify-center align-middle text-center font-bold">
          <h3 className="text-nc-4xl">{t("player:swipeDown")}</h3>
        </div>
      )}
      {gameInfo && embedInfo && !errorMessage && (
        <Player
          style={{
            opacity:
              (!supportsFullscreen() && isScrolledDown) || supportsFullscreen()
                ? 1
                : 0,
          }}
          gameId={gameInfo.id}
          gameInfo={gameInfo}
          enableTracking={embedInfo.enableTracking}
          embedId={params.embedId}
          autostart={true}
          isFullscreen={true}
          onStartPlaying={
            supportsFullscreen() ? () => setSetupFullscreen(true) : undefined
          }
        />
      )}
      {errorMessage && <div className="status-text">{errorMessage}</div>}
      <FullscreenFeatures
        onQuit={quitGame}
        parentRef={containerRef}
        shouldSetupFullscreen={setupFullscreen}
        showQuitButton={true}
      />
    </div>
  );
};

export default FullscreenPlayer;
