import axios from "axios";

export class ApiService {
  get baseUrl() {
    const domain =
      process.env.REACT_APP_CORE_API_BASE_URL || "https://api.apprend.ly";
    return `${domain}/api/v1`;
  }

  getApiUrl(uri) {
    return `${this.baseUrl}/${uri}`;
  }

  apiRequestGet(uri, queryParams = {}, options) {
    return axios.get(this.getApiUrl(uri), { ...options, params: queryParams });
  }

  apiRequestPatch(uri, entityId, data) {
    return axios.patch(this.getApiUrl(`${uri}/${entityId}`), data)
  }
}
