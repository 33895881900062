export class ServiceBase {
  eventListeners = {};

  /**
   * Add a callback function for a WebRTC event.
   *
   * @param {string} eventName
   * @param {function} callback
   */
  addEventListener(eventName, callback) {
    this.eventListeners[eventName] = this.eventListeners[eventName]
      ? [...this.eventListeners[eventName], callback]
      : [callback];
  }

  /**
   * Set callback function for a WebRTC event. Removes any previous listeners.
   *
   * @param {string} eventName
   * @param {function} callback
   */
  setEventListener(eventName, callback) {
    this.eventListeners[eventName] = [callback];
  }

  /**
   * Remove all event listeners for a named event.
   *
   * @param {string} eventName
   */
  removeAllEventListeners(eventName) {
    delete this.eventListeners[eventName];
  }

  /**
   * Call all event listeners connected to a named event.
   *
   * @param {string} eventName
   * @param {*} data
   */
  callEventListeners(eventName, ...args) {
    if (
      this.eventListeners[eventName] &&
      this.eventListeners[eventName].length
    ) {
      this.eventListeners[eventName].forEach((listener) => listener(...args));
    }
  }
}
