import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { rootSaga, rootReducer } from "saga-slice";

import playerSlice from "../features/player/playerSlice";
import demoSlice from "../features/demo/demoSlice";
import embedSlice from "../features/embed/embedSlice";

const modules = [playerSlice, demoSlice, embedSlice];

const sagaMiddleware = createSagaMiddleware();
const appReducer = rootReducer(modules);
const middleware = applyMiddleware(...[sagaMiddleware]);

const store = createStore(appReducer, middleware);
sagaMiddleware.run(rootSaga(modules));

export default store;
