import {
  isChrome,
  isChromium,
  isEdge,
  isFirefox,
  isIOS,
  isMobileOnly,
  isSafari,
} from "react-device-detect";

export const isSupportedBrowser = () => {
  return isChrome || isChromium || isEdge || isSafari || isFirefox;
};

export const supportedBrowsers = [
  "Google Chrome",
  "Firefox",
  "Safari",
  "Microsoft Edge",
];

export const supportsFullscreen = () => {
  if (isIOS && isMobileOnly) {
    return false;
  }

  const el = document.body;

  if (!el) return false;

  return (
    el.requestFullscreen ||
    el.mozRequestFullScreen ||
    el.msRequestFullscreen ||
    el.webkitRequestFullScreen
  );
};

export const isFullscreen = () => {
  const fullscreenMode =
    document.webkitIsFullScreen ||
    document.mozFullScreen ||
    (document.msFullscreenElement && document.msFullscreenElement !== null) ||
    (document.fullscreenElement && document.fullscreenElement !== null);

  return !!fullscreenMode;
};

export const setFullscreenListener = (callback) => {
  document.addEventListener("webkitfullscreenchange", callback, false);
  document.addEventListener("mozfullscreenchange", callback, false);
  document.addEventListener("fullscreenchange", callback, false);
  document.addEventListener("MSFullscreenChange", callback, false);
};

export const supportsVibrate = () => {
  return !!("vibrate" in navigator);
};

/**
 * Go into fullscreen with one specific HTML element.
 *
 * @param {*} fullscreenElement
 * @returns {boolean} True if going fullscreen succeeded, otherwise false.
 */
export const startFullscreenElement = (fullscreenElement) => {
  let fullscreenFunc = fullscreenElement.requestFullscreen;

  if (!fullscreenFunc) {
    [
      "mozRequestFullScreen",
      "msRequestFullscreen",
      "webkitRequestFullScreen",
    ].forEach(function (req) {
      fullscreenFunc = fullscreenFunc || fullscreenElement[req];
    });
  }

  if (fullscreenFunc) {
    fullscreenFunc.call(fullscreenElement);
    return true;
  }

  return false;
};

export const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
};
