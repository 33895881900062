import { put } from "redux-saga/effects";
import { createModule } from "saga-slice";
import { ErrorCodes } from "../../helpers/constants";
import { EmbedService } from "../../services/EmbedService";

const embedService = new EmbedService();

export const embedSlice = createModule({
  name: "embed",
  initialState: {
    embedToken: "",
    embedInfo: null,
    isFetching: false,
    hasError: false,
    errorMessage: "",
  },

  reducers: {
    fetchEmbed: (state, token) => {
      state.isFetching = true;
    },
    fetchSuccess: (state, gameInfo) => {
      state.isFetching = false;
      state.embedInfo = gameInfo;
    },
    fetchFail: (state, message) => {
      state.hasError = true;
      state.errorMessage = message;
    },
  },

  sagas: (A) => ({
    *[A.fetchEmbed]({ payload }) {
      try {
        const result = yield embedService
          .getEmbedDetails(payload)
          .then((result) => result.data);
        yield put(A.fetchSuccess(result));
      } catch (error) {
        let errorMessage;

        if (error.response && error.response.status === 500) {
          errorMessage = ErrorCodes.CoreApiServerError;
        } else if(error.response && error.response.status === 403) {
          errorMessage = ErrorCodes.EmbedNotInDomain;
        } else if (error.response?.status === 404) {
          errorMessage = ErrorCodes.EmbedNotFound;
        } else {
          errorMessage = ErrorCodes.EmbedInactive;
        }

        yield put(A.fetchFail(errorMessage));
      }
    },
  }),
});

export const selectEmbedInfo = (state) => state.embed.embedInfo;
export const selectErrorMessage = (state) => state.embed.errorMessage;

export const { actions } = embedSlice;
export default embedSlice;
