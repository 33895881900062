import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  exitFullscreen,
  isFullscreen,
  setFullscreenListener,
  startFullscreenElement,
  supportsFullscreen,
} from "../../helpers/device";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { ReactComponent as RotateIcon } from "../../img/icons/icon-rotate-phone.svg";

const FullscreenFeatures = (props) => {
  const { t } = useTranslation();

  const [showQuitConfirmation, setShowQuitConfirmation] = useState(false);
  const [showFullscreenResume, setShowFullscreenResume] = useState(false);

  useEffect(() => {
    if (props.shouldSetupFullscreen) {
      setFullscreenListener(onFullscreenChange);
      startFullscreen();
    } else if (props.shouldSetupFullscreen === false && isFullscreen()) {
      exitFullscreen();
    }
  }, [props.shouldSetupFullscreen]);

  const onFullscreenChange = () => {
    setShowFullscreenResume(!isFullscreen());
  };

  const startFullscreen = () => {
    setShowFullscreenResume(false);
    const fullscreenEl = props.parentRef.current;
    startFullscreenElement(fullscreenEl);
  };

  return (
    <>
      {props.enableRotateWarning && (
        <div className="orientation-overlay landscape:hidden w-screen h-screen fixed top-0 left-0 z-50 bg-dusk flex flex-col justify-center align-middle text-center font-bold">
          <p className="text-nc-4xl">{t("player:rotateDevice")}</p>
          <RotateIcon className="w-screen h-44 mt-8" />
        </div>
      )}

      {showFullscreenResume && supportsFullscreen() && (
        <div className="w-screen h-screen bg-dusk flex flex-col justify-center align-middle text-center self-center fixed z-50 top-0">
          <button
            className="border-4 bg-pink border-white rounded-sm text-xl px-4 py-1 sm:px-10 sm:py-2 font-semibold self-center"
            onTouchEnd={startFullscreen}
          >
            {t("player:resumeFullscreen")}
          </button>
        </div>
      )}
      {props.showQuitButton && (
        <div
          onTouchEnd={() => setShowQuitConfirmation(true)}
          className="icon-container fixed left-2 top-2 w-10 h-10 z-50"
        >
          <div className="quit-game w-10 h-10"></div>
        </div>
      )}
      {showQuitConfirmation && (
        <div className="w-screen h-screen fixed top-0 left-0 z-50 bg-dusk flex flex-col justify-center align-middle text-center">
          <p className="text-nc-3xl mb-4 font-bold">
            {t("player:quitConfirmation")}
          </p>
          <div className="flex-row">
            <button
              className="button button-md inline mr-2 font-semibold"
              onTouchEnd={props.onQuit}
            >
              {t("player:quit")}
            </button>
            <button
              className="button button__alternative button-md inline font-semibold"
              onTouchEnd={() => setShowQuitConfirmation(false)}
            >
              {t("player:cancel")}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

FullscreenFeatures.propTypes = {
  onQuit: PropTypes.func.isRequired,
  showQuitButton: PropTypes.bool,
};

export default FullscreenFeatures;
