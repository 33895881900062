import { useState } from "react";
import { useTranslation } from "react-i18next";

const PlayerDataModal = ({
  openPlayerModal,
  updatePlayerData,
  closePlayerDataModal,
}) => {
  const { t } = useTranslation();

  const [input, setInput] = useState("");

  const handleKeyPress = (event) => {
    // look for the `Enter` keyCode
    if (event.keyCode === 13 || event.which === 13) {
      updatePlayerData(input);
      event.target.blur();
    }
  }

  return (
    <div
      className={`
            flex 
            justify-center 
            items-center 
            h-screen 
            w-screen 
            bg-dusk 
            overflow-x-hidden 
            overflow-y-auto 
            fixed
            inset-0 
            outline-none
            focus:outline-none
            ${openPlayerModal ? "opacity-100 z-60" : "opacity-0 z-0"}`}
      style={{
        transition: "opacity .5s ease-in",
      }}
    >
      <div className="relative w-auto my-6 mx-auto max-w-3xl">
        <div className="relative flex flex-col w-full">
          <div className="flex items-center p-5 mt-6 rounded-t text-center">
            <h3 className="text-base w-full">{t("player:whatIsYourName")}</h3>
          </div>
          <div className="relative flex-auto">
            <div className="rounded px-4 w-full">
              <input
                className="
                      border
                      border-solid
                      rounded-sm
                      w-full
                      py-2
                      px-4
                      text-black
                      bg-white
                      border-duskLight
                      outline-none
                      focus:outline-none"
                value={input}
                onInput={(e) => setInput(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </div>
          </div>
          <div className="flex items-center justify-center p-6">
            <button
              className="
                    text-sm
                    px-6
                    py-2
                    text-black
                    rounded-sm
                    border
                    border-solid
                    border-yellow
                    border-xl
                    bg-yellowLight
                    outline-none
                    focus:outline-none
                    hover:bg-yellowDark
                    hover:border-yellowDark"
              type="submit"
              onClick={() => updatePlayerData(input)}
            >
              {t("player:submit")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerDataModal;
