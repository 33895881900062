export const LogLevel = {
  debug: {
    name: "debug",
    level: 0,
    function: "log",
    color: "gray",
  },
  info: {
    name: "info",
    level: 1,
    function: "log",
    color: "green",
  },
  warning: {
    name: "warning",
    level: 2,
    function: "warn",
    color: "yellow",
  },
  error: {
    name: "error",
    level: 3,
    function: "error",
    color: "red",
  },
};

export const getCurrentLogLevel = () => {
  const minLogLevel = process.env.REACT_APP_LOGGING_LEVEL || "info",
    minLogLevelSettings = LogLevel[minLogLevel];

  return minLogLevelSettings;
};

export const log = (level, ...data) => {
  const minLogLevelSettings = getCurrentLogLevel();

  if (level.level < minLogLevelSettings.level) {
    return;
  }

  console[level.function](
    `%c[${level.name}] `,
    `color: ${level.color};`,
    ...data
  );
};
