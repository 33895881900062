import { useEffect } from "react";
import { useParams } from "react-router-dom";

const IFrame = () => {

  const params = useParams();

  useEffect(() => {

    const logEvents = (event) => {
      console.log(event);
    }

    window.addEventListener('message', logEvents)

    return () => {
      window.removeEventListener('message', logEvents)
    }
  })

  return (
    <iframe
        title='Iframe'
        src={`${process.env.PUBLIC_URL}/embed/${params.embedId}?loadedFrom=iframe`}
        width="100%" 
        height="800px"
        allowFullScreen
      >
    </iframe>
  )
}

export default IFrame;