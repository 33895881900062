import { put } from "redux-saga/effects";
import { createModule } from "saga-slice";
import { GameService } from "../../services/GameService";
import { SignallingService } from "../../services/SignallingService";
import { ErrorCodes } from "../../helpers/constants";
import { setItem } from "../../services/Storage";

const gameService = new GameService();

export const playerSlice = createModule({
  name: "player",
  initialState: {
    gameId: "",
    gameInfo: null,
    isFetching: false,
    hasError: false,
    errorMessage: "",
    signallingService: new SignallingService(),
    signallingConnected: false,
    playerId: null,
    isScrolledDown: false,
  },

  reducers: {
    setError: (state, error) => {
      state.errorMessage = error;
    },
    fetchGame: (state, id) => {
      state.isFetching = true;
    },
    setGameInfo: (state, gameInfo) => {
      state.gameInfo = gameInfo;
    },
    fetchSuccess: (state, gameInfo) => {
      state.isFetching = false;
      state.gameInfo = gameInfo;
    },
    fetchFail: (state, message) => {
      state.hasError = true;
      state.errorMessage = message;
    },
    setupSignallingConnection: (state, payload) => {
      state.signallingService.init(payload.gameId);
    },
    setPlayerId: (state, payload) => {
      state.playerId = payload;
      setItem("apprendly:playerId", payload);
    },
    setIsScrolledDown: (state, payload) => {
      state.isScrolledDown = payload;
    },
  },

  sagas: (A) => ({
    *[A.fetchGame]({ payload }) {
      try {
        const result = yield gameService
          .getGameDetails(payload)
          .then((result) => result.data);
        yield put(A.fetchSuccess(result));
      } catch (error) {
        let errorMessage = error.message;

        if (error.response && error.response.status === 404) {
          errorMessage = ErrorCodes.GameNotFound;
        } else if (error.response && error.response.status === 500) {
          errorMessage = ErrorCodes.CoreApiServerError;
        } else {
          errorMessage = ErrorCodes.GameNotFound;
        }

        yield put(A.fetchFail(errorMessage));
      }
    },
  }),
});

export const selectGame = (state) => state.player.gameInfo;
export const selectErrorMessage = (state) => state.player.errorMessage;
export const selectSignallingService = (state) =>
  state.player.signallingService;
export const selectPlayerId = (state) => state.player.playerId;
export const selectIsScrolledDown = (state) => state.isScrolledDown;

export const { actions } = playerSlice;
export default playerSlice;
