export const localStorageSupported = () => {
  var storageSupported = false;

  try {
    storageSupported = window.localStorage && true;
  } catch (e) {}

  return storageSupported;
};

export const setItem = (key, value) => {
  if (!localStorageSupported()) {
    return;
  }
  localStorage.setItem(key, JSON.stringify(value));
};

export const getItem = (key) => {
  if (!localStorageSupported()) {
    return null;
  }

  return JSON.parse(localStorage.getItem(key));
};

export const removeItem = (key) => {
  if (!localStorageSupported()) {
    return null;
  }

  return localStorage.removeItem(key);
};
