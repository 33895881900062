import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Player from "./Player";
import { supportsFullscreen } from "../../helpers/device";
import FullscreenFeatures from "./FullscreenFeatures";
import { useQueryParams } from "use-query-params";
import { isMobileOnly } from "react-device-detect";

const FullPagePlayer = (props) => {
  const { t } = useTranslation();

  const containerRef = useRef();
  const [errorMessage] = useState(null);
  const [setupFullscreen] = useState(null);
  const [, setIsScrolledDown] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  const onScroll = useCallback(() => {
    if (supportsFullscreen()) {
      return;
    }

    const scrolledDown = window.scrollY >= window.innerHeight / 2 + 100;
    setIsScrolledDown(scrolledDown);
  }, []);

  useEffect(() => {
    if (!supportsFullscreen()) {
      window.addEventListener("scroll", onScroll);
      window.addEventListener("orientationchange", () => {
        window.scrollTo(0, 0);
      });
    } else {
      setIsScrolledDown(true);
    }
  }, [onScroll]);

  /**
   * Send a message to the embed's owner window.
   */
  const sendEmbedMessage = useCallback(
    (type, data = {}) => {
      data = { apprendly: true, id: props.embedId, type, ...data };

      if (window.parent) {
        window.parent.postMessage(data, "*");
      }
    },
    [props.embedId]
  );

  const quitGame = () => {
    sendEmbedMessage("quitGame");
  };

  const onStartLoading = () => {
    setHasLoaded(true);
  };

  return (
    <div ref={containerRef} className="apprendly-embed">
      {props.embedId && !errorMessage && (
        <Player
          gameId={props.gameId}
          embedId={props.embedId}
          autostart={props.autostart}
          isFullscreen={true}
          isFullPage={true}
          onStartLoading={onStartLoading}
          enableTracking={true}
          playerId={props.playerId}
        />
      )}
      {errorMessage && <div className="status-text">{errorMessage}</div>}
      <FullscreenFeatures
        onQuit={quitGame}
        parentRef={containerRef}
        shouldSetupFullscreen={setupFullscreen}
        showQuitButton={hasLoaded && isMobileOnly}
        enableRotateWarning={supportsFullscreen()}
      />
    </div>
  );
};

FullPagePlayer.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default FullPagePlayer;
