import { ServiceBase } from "./ServiceBase";
import { io } from "socket.io-client";
import { log, LogLevel } from "../helpers/logger";

export class SignallingService extends ServiceBase {
  socket = null;
  callbacks = {};
  initiated = false;
  paused = false;
  socketHost = "";

  constructor() {
    super();

    const socketProtocol =
      window.location.protocol === "http:" ? "ws:" : "wss:";
    const domain = this.getSocketHostDomain();
    const port = process.env.REACT_APP_CORE_API_PXS_PORT || 3210;

    this.socketHost = `${socketProtocol}//${domain}:${port}`;
  }

  get connected() {
    return this.socket && this.socket.connected;
  }

  init() {
    log(
      LogLevel.debug,
      "Init signalling service and connection",
      this.initiated
    );

    if (this.initiated) {
      return;
    }

    this.initiated = true;
    this.socket = io(`${this.socketHost}`, { transports: ["websocket"] });

    this.socket.addEventListener("connect", () => {
      this.callEventListeners("connect");
    });

    this.socket.addEventListener("disconnect", (event) => {
      if (!this.paused) {
        this.callEventListeners("disconnect", event);
      }
    });

    this.socket.on("message", (type, data) => {
      this.callEventListeners("message", type, data);
    });

    this.socket.on("connect_error", (event) => {
      this.callEventListeners("connect_error", event);
    });

    this.socket.io.on("reconnect", (event) => {
      this.callEventListeners("reconnect", event);
    });
  }

  getSocketHostDomain = () => {
    let domain = process.env.REACT_APP_CORE_API_PXS_DOMAIN || "pxs.apprend.ly";

    const regionMatches = window.location.host.match(/([a-z-]+)\.play/i);
    if (
      regionMatches &&
      regionMatches[1] &&
      !process.env.PUBLIC_URL.includes(`${regionMatches[1]}.play`)
    ) {
      return `${regionMatches[1]}.${domain}`;
    }

    return domain;
  };

  pause() {
    if (!this.connected) {
      return;
    }

    this.paused = true;
    this.disconnect();
  }

  resume() {
    this.socket.connect();
  }

  disconnect() {
    if (!this.connected) {
      return;
    }

    this.socket.disconnect();
  }

  connect() {
    if (this.connected) {
      return;
    }

    if (!this.initiated) {
      return this.init();
    }

    this.socket.connect();
  }

  sendMessage(type, data, callback) {
    if (this.connected) {
      this.socket.send(type, data, (...args) => {
        callback(...args);
      });
    }
  }

  async getWebRtcConfig() {
    return new Promise((resolve, reject) => {
      if (this.connected) {
        this.socket.send("getConfig", (config) => resolve(config));
      } else {
        reject();
      }
    });
  }
}
