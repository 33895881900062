import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectSignallingService } from "./playerSlice";
import { log, LogLevel } from "../../helpers/logger";

const PopupPlayer = (props) => {
  const { t } = useTranslation();

  const signallingService = useSelector(selectSignallingService);

  const onClickPlay = () => {
    const url = `${window.location.origin}/fullscreen/${props.embedId}`;
    const newWindow = window.open(url);
    signallingService.pause();

    newWindow.addEventListener("beforeunload", () => {
      log(LogLevel.debug, "Popup player window closed");
      signallingService.resume();
    });
  };

  return (
    <div className="self-center text-center" onTouchEnd={onClickPlay}>
      <h2 className="text-3xl mb-3 font-bold sm:mb-6">
        {t("player:readyToPlay")}
      </h2>
      <button className="button">{t("player:continue")}</button>
    </div>
  );
};

PopupPlayer.propTypes = {
  gameId: PropTypes.string.isRequired,
  embedId: PropTypes.string.isRequired,
};

export default PopupPlayer;
