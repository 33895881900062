import { UEController } from "./UEController";
import {
  ToClientMessageType,
  ToStreamerMessageType,
} from "../helpers/constants";
import { ServiceBase } from "./ServiceBase";
import { LatencyTester } from "./LatencyTester";

export class UEConnectionService extends ServiceBase {
  printDebug = true;

  webRtcConn = null;
  controller = null;

  latencyTestRunning = false;
  latencyTestInterval = null;
  latencyTester;

  constructor(webRtcConn, playerEl) {
    super();

    this.webRtcConn = webRtcConn;
    this.controller = new UEController(this, playerEl);

    this.webRtcConn.setEventListener("videoInitialised", () => {
      this.callEventListeners("connectionOpened");
    });

    this.webRtcConn.setEventListener("dataChannelMessage", ({ type, data }) => {
      if (type === ToClientMessageType.LatencyTest) {
        this.onLatencyTestResult(data);
      }

      this.callEventListeners("onMessage", { type, data });
    });

    this.latencyTester = new LatencyTester();
    this.latencyTester.setEventListener("latencyStats", (data) => {
      this.callEventListeners("latencyStats", data);
    });
  }

  /**
   * Send data to UE.
   *
   * @param {Buffer} data The data to be sent, as a buffer.
   * @param {int} type
   */
  sendData = (data, type) => {
    this.webRtcConn.send(data, type);
  };

  /**
   * Send input data to UE.
   *
   * @param {Buffer} inputData The data to be sent, as a buffer.
   */
  sendInputData = (data) => {
    this.sendData(data, ToStreamerMessageType.UIInteraction);
  };

  /**
   * Send a command with optional params to UE.
   *
   * @param {string} command
   * @param {object} params
   */
  sendCommand = (command, params) => {
    let descriptor = {
      Command: command,
      ...params,
    };

    return this.sendJson(descriptor, ToStreamerMessageType.UIInteraction);
  };

  /**
   * Send a JSON message of any type to UE.
   *
   * @param {object} json
   * @param {ToStreamerMessageType} messageType
   */
  sendJson = (json, messageType) => {
    let jsonString = JSON.stringify(json);

    // Add the UTF-16 JSON string to the array byte buffer, going two bytes at
    // a time.
    let data = new DataView(new ArrayBuffer(1 + 2 + 2 * jsonString.length));
    let byteIdx = 0;
    data.setUint8(byteIdx, messageType);
    byteIdx++;
    data.setUint16(byteIdx, jsonString.length, true);
    byteIdx += 2;

    for (let i = 0; i < jsonString.length; i++) {
      data.setUint16(byteIdx, jsonString.charCodeAt(i), true);
      byteIdx += 2;
    }

    this.sendData(data.buffer, messageType);
  };

  /**
   * Request quality control; the current user's connection should control the bitrate
   * of the video stream from UE.
   */
  requestQualityControl = () => {
    this.sendData(
      new Uint8Array([ToStreamerMessageType.RequestQualityControl]).buffer,
      ToStreamerMessageType.RequestQualityControl
    );
  };

  /**
   * Set up periodic latency testing, at given interval.
   *
   * @param {number} interval Interval length in milliseconds.
   * @returns
   */
  startLatencyTesting = (interval) => {
    if (this.latencyTestRunning) {
      return;
    }

    this.latencyTestRunning = true;
    this.latencyTestInterval = setInterval(() => {
      this.startLatencyTest();
    }, interval);
  };

  /**
   * Stop running periodic latency tests.
   */
  stopLatencyTesting = () => {
    clearInterval(this.latencyTestInterval);
    this.latencyTestRunning = false;
  };

  /**
   * Start a latency test, gathering latency info from UE, connection and browser.
   *
   * @param {*} onTestStarted
   * @returns
   */
  startLatencyTest = () => {
    if (!this.webRtcConn.playerEl) {
      return;
    }

    this.latencyTester.startLatencyTest();

    let descriptor = {
      StartTime: this.latencyTester.TestStartTimeMs,
    };

    this.sendJson(descriptor, ToStreamerMessageType.LatencyTest);
  };

  onLatencyTestResult = (data) => {
    let latencyTimingsAsString = new TextDecoder("utf-16").decode(data);

    let latencyTimingsFromUE = JSON.parse(latencyTimingsAsString);
    this.latencyTester.setUETimings(latencyTimingsFromUE);
  };

  /**
   * Set the desired resolution.
   */
  setResolution = (width, height) => {
    this.sendJson(
      { "Resolution.Width": width, "Resolution.Height": height },
      ToStreamerMessageType.Command
    );
  };
}
