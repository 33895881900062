import React, { useState } from "react";
import PropTypes from "prop-types";
import { UEConnectionService } from "../../services/UEConnectionService";

const UEConsole = function ({ ueConnection, toggleVisibility, consoleValue }) {
  const [inputValue, setInputValue] = useState("");

  const sendCommand = () => {
    ueConnection.sendCommand("ExecuteConsoleCommand", {
      ConsoleCommand: inputValue,
    });
    setInputValue("");
  };

  const getValue = () => {
    ueConnection.sendCommand("GetConsoleValue", { ValueName: inputValue });
    setInputValue("");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    sendCommand();
  };

  return (
    <div className="absolute bottom-0 left-0 right-0  flex flex-col">
      {consoleValue && (
        <div className="p-2 bg-white bg-opacity-70 text-black text-left">
          {consoleValue.name}: {consoleValue.value}
        </div>
      )}

      <div className="p-4 flex flex-row w-full bg-black bg-opacity-70">
        <form onSubmit={onSubmit} className="flex-grow mr-2">
          <input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onFocus={(e) => ueConnection.controller.unregisterKeyboardEvents()}
            onBlur={(e) => ueConnection.controller.registerKeyboardEvents()}
            type="text"
            className="w-full bg-transparent rounded-md py-1 px-2 border-white text-black"
            placeholder="Console command line (Enter to send)"
          />
        </form>
        <button
          onClick={sendCommand}
          className="bg-yellow rounded-lg text-black font-semibold mr-2 px-4"
        >
          Send command
        </button>
        <button
          onClick={getValue}
          className="bg-yellow rounded-lg text-black font-semibold mr-2 px-4"
        >
          Get value
        </button>
        <button
          onClick={() => toggleVisibility(false)}
          className="w-8 h-8 rounded-full bg-yellow text-black font-bold"
        >
          X
        </button>
      </div>
    </div>
  );
};

UEConsole.propTypes = {
  ueConnection: PropTypes.instanceOf(UEConnectionService).isRequired,
  toggleVisibility: PropTypes.func.isRequired,
};

export default UEConsole;
