import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { UEConnectionService } from "../../services/UEConnectionService";
import { log, LogLevel } from "../../helpers/logger";

const NetworkChecker = function ({ ueConn }) {
  const { t } = useTranslation();

  const [slowConnection, setSlowConnection] = useState(false);

  useEffect(() => {
    log(LogLevel.debug, "Starting periodic latency test");

    ueConn.startLatencyTesting(5000);

    ueConn.setEventListener("latencyStats", (data) => {
      const highLatency = data.networkLatency > 350;
      setSlowConnection(highLatency);
      log(LogLevel.debug, "Received latency stats", data);
    });
  }, [ueConn]);

  return (
    <>
      {slowConnection && (
        <div className="top-2 left-0 self-align-center inline relative bg-yellow px-2 py-1 text-black text-2xs z-50">
          {t("player:unstableConnectionWarning")}
        </div>
      )}
    </>
  );
};

NetworkChecker.propTypes = {
  ueConn: PropTypes.instanceOf(UEConnectionService).isRequired,
};

export default NetworkChecker;
