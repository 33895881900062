import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import PropTypes from "prop-types";
import classNames from "classnames";
import { getErrorKey } from "../helpers/userMessages";

const ErrorMessage = (props) => {
  const { t } = useTranslation();

  const errorKey = props.errorKey ?? getErrorKey(props.errorCode);

  const getDescriptionKey = () => {
    return `errors:${errorKey}.description`;
  };

  return (
    <div
      className={classNames(
        "absolute top-0 left-0 w-screen h-screen bg-pink flex flex-col px-2 sm:px-10 align-middle justify-center text-center",
        `error-${errorKey}`
      )}
    >
      <h2 className="text-2xl mb-2">{t(`errors:${errorKey}.header`)}</h2>
      {i18n.exists(getDescriptionKey()) && (
        <p className="text-base mb-2">{t(getDescriptionKey())}</p>
      )}
      {props.errorCode && (
        <p className="error-code text-xs">
          {t("general:errorCode")}: {props.errorCode}
        </p>
      )}
    </div>
  );
};

ErrorMessage.propTypes = {
  errorCode: PropTypes.number,
  errorKey: PropTypes.string,
};

export default ErrorMessage;
