import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { WebRtcService } from "../../services/WebRtcService";
import { log, LogLevel } from "../../helpers/logger";
import { ToStreamerMessageType } from "../../helpers/constants";

const settings = {
  afkWarningTime: 540,
  afkDisconnectTime: 60,
};

const AfkMonitor = function ({
  webRtcConn,
  onDisconnect,
  onAfkWarningClicked,
}) {
  const { t } = useTranslation();
  const listenersSet = useRef(false);

  const warningTimer = useRef(null);
  const disconnectInterval = useRef(null);
  const [countdownTime, setCountdownTime] = useState(
    settings.afkDisconnectTime
  );

  /**
   * Set up a disconnect timeout, while showing the Afk warning overlay.
   */
  const setupDisconnectTimeout = useCallback(() => {
    log(LogLevel.debug, "Set up disconnect timeout");
    setCountdownTime(settings.afkDisconnectTime);

    if (warningTimer.current !== null) {
      clearTimeout(warningTimer.current);
      warningTimer.current = null;
    }

    if (disconnectInterval.current === null) {
      let timeLeft = settings.afkDisconnectTime;
      console.log("Setting interval: disconnectInterval");
      disconnectInterval.current = setInterval(() => {
        if (timeLeft <= 0) {
          clearInterval(disconnectInterval.current);
          clearTimeout(warningTimer.current);

          webRtcConn.close();
          if (onDisconnect) {
            onDisconnect();
          }
        }

        setCountdownTime(timeLeft--);
      }, 1000);
    }
  }, [disconnectInterval, onDisconnect, webRtcConn]);

  /**
   * Reset the warning timeout. Shows a warning when the time runs out.
   */
  const resetWarningTimeout = useCallback(() => {
    if (warningTimer.current !== null) {
      clearTimeout(warningTimer.current);
      warningTimer.current = null;
    }

    if (disconnectInterval.current === null) {
      warningTimer.current = setTimeout(
        setupDisconnectTimeout,
        settings.afkWarningTime * 1000
      );
    }
  }, [warningTimer, setupDisconnectTimeout]);

  /**
   * Cancel the disconnect timer.
   */
  const cancelDisconnectTimeout = useCallback(() => {
    clearInterval(disconnectInterval.current);
    disconnectInterval.current = null;
    setCountdownTime(settings.afkDisconnectTime);
    resetWarningTimeout();
  }, [disconnectInterval, resetWarningTimeout]);

  /**
   * Set up listener for data on the WebRTC channel. If we are sending data, cancel the timeouts.
   */
  useEffect(() => {
    if (!listenersSet.current) {
      listenersSet.current = true;

      webRtcConn.setEventListener("dataSent", (type) => {
        if (type === ToStreamerMessageType.UIInteraction) {
          if (disconnectInterval.current) {
            cancelDisconnectTimeout();
          }

          resetWarningTimeout();
        }
      });
    }
  }, [
    cancelDisconnectTimeout,
    disconnectInterval,
    resetWarningTimeout,
    webRtcConn,
    countdownTime,
  ]);

  /**
   * Clear all listeners for the WebRTC channel.
   */
  useEffect(() => {
    return function cleanup() {
      clearInterval(disconnectInterval.current);
      clearTimeout(warningTimer.current);
      webRtcConn.removeAllEventListeners("dataSent");
      listenersSet.current = false;
    };
  }, [webRtcConn]);

  /**
   * When clicking the warning overlay, cancel the timers.
   */
  const warningClicked = () => {
    cancelDisconnectTimeout();

    if (onAfkWarningClicked) {
      onAfkWarningClicked();
    }
  };

  log(LogLevel.debug, disconnectInterval.current);

  return !disconnectInterval.current ? null : (
    <div
      className="afk-warning absolute w-screen h-screen left-0 top-0 bg-dusk bg-opacity-99 flex flex-col justify-center align-middle text-center"
      onClick={warningClicked}
    >
      <h1 className="text-3xl mb-5">{t("player:afkWarningHeader")}</h1>
      <h3 className="text-xl mb-5">{t("player:moveToContinue")}</h3>
      <h3 className="text-lg">
        {t("player:disconnectingCountdown", { countdownTime })}
      </h3>
    </div>
  );
};

AfkMonitor.propTypes = {
  webRtcConn: PropTypes.instanceOf(WebRtcService).isRequired,
  onDisconnect: PropTypes.func,
  onWarningClick: PropTypes.func,
};

export default AfkMonitor;
