import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./i18n/en.json";
import nb from "./i18n/nb.json";
import pl from "./i18n/pl.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: en,
      nb: nb,
      no: nb,
      pl: pl,
    },
    keySeparator: ".",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      caches: ["localStorage", "cookie"],
      order: ["querystring", "cookie", "localStorage", "navigator"],
    },
  });

export default i18n;
