import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AllowedDomains, ErrorCodes } from "../../helpers/constants";
import { useParams } from "react-router-dom";
import { selectEmbedInfo, selectErrorMessage, actions } from "./embedSlice";
import Player from "../player/Player";
import ErrorMessage from "../../components/ErrorMessage";
import { useQueryParams, StringParam } from "use-query-params";
import { removeItem } from "../../services/Storage";
import FullPagePlayer from "../player/FullPagePlayer";

export function Embed(props) {
  const embedInfo = useSelector(selectEmbedInfo);
  const errorMessage = useSelector(selectErrorMessage);
  const dispatch = useDispatch();
  const params = useParams();
  const [queryParams] = useQueryParams({
    autostart: StringParam,
    playerId: StringParam,
    enableTracking: StringParam,
    resetPlayerData: StringParam,
    gameId: StringParam,
    fullPage: StringParam,
  });

  const getGameId = () => {
    if (embedInfo.game && embedInfo.game.id) {
      return embedInfo.game.id;
    } else if (
      embedInfo.course &&
      queryParams.gameId &&
      embedInfo.course.games.indexOf(queryParams.gameId) >= 0
    ) {
      return queryParams.gameId;
    }

    return null;
  };

  const isDomainAllowed = (domains) => {
    const domain = window.location.hostname;
    let inAllowedDomains = false;

    AllowedDomains.forEach((allowedDomain) => {
      const index = domain.indexOf(allowedDomain);
      if (index !== -1) {
        inAllowedDomains = true;
      }
    });

    if (inAllowedDomains) {
      return true;
    }

    if (domains.includes(domain)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!embedInfo && params.embedId) {
      dispatch(actions.fetchEmbed(params.embedId));
    }
  }, [params.token, dispatch, embedInfo, params.embedId]);

  useEffect(() => {
    window.apprendly.initialize();

    if (queryParams.resetPlayerData === "true") {
      removeItem("apprendly:webplayer:playerData");
      removeItem("apprendly:playerId");
    }
  }, []);

  useEffect(() => {
    if (embedInfo) {
      // Check if embed is restricted for specific domains.
      if (embedInfo.allowedDomains.length) {
        const inDomains = isDomainAllowed(embedInfo.allowedDomains);
        if (!inDomains) {
          dispatch(actions.fetchFail(ErrorCodes.EmbedNotInDomain));
        }
      }
      document.title = embedInfo.name;
    }
  }, [embedInfo, dispatch]);

  return (
    <div id="embed-wrapper" className="apprendly-embed">
      {embedInfo &&
        !errorMessage &&
        getGameId() &&
        queryParams.fullPage !== "true" && (
          <div className="embed-player-wrapper">
            <i className="embed-player-sizer"></i>
            <Player
              gameId={getGameId()}
              embedId={params.embedId}
              autostart={queryParams.autostart === "true"}
              playerId={queryParams.playerId}
              enableTracking={queryParams.enableTracking !== "false"}
            />
          </div>
        )}
      {embedInfo &&
        !errorMessage &&
        getGameId() &&
        queryParams.fullPage === "true" && (
          <div className="embed-player-wrapper">
            <FullPagePlayer
              gameId={getGameId()}
              embedId={embedInfo.accessToken}
              autostart={queryParams.autostart === "true"}
              playerId={queryParams.playerId}
              enableTracking={queryParams.enableTracking !== "false"}
            />
          </div>
        )}
      {embedInfo && !errorMessage && !getGameId() && (
        <ErrorMessage errorCode={ErrorCodes.DemoNotFound} />
      )}
      {errorMessage && <ErrorMessage errorCode={errorMessage} />}
    </div>
  );
}
