import { ServiceBase } from "./ServiceBase";

export class WebRtcService extends ServiceBase {
  config = null;
  playerEl = null;
  isConnected = false;

  pcClient = null;
  dcClient = null;
  tnClient = null;

  sdpConstraints = {
    offerToReceiveAudio: 1,
    offerToReceiveVideo: 1,
    voiceActivityDetection: false,
  };

  dataChannelOptions = { ordered: true };

  printDebugMessages = false;

  /**
   * Set the video player element.
   *
   * @param {*} playerEl
   */
  setPlayer(playerEl) {
    this.playerEl = playerEl;

    if (this.config) {
      this.setupConnection();
    }
  }

  /**
   * Set the WebRTC config to be used for the connection.
   *
   * @param {*} config
   */
  setConfig(config) {
    this.config = {
      ...config,
      sdpSemantics: "unified-plan",
    };

    if (this.playerEl) {
      this.setupConnection();
    }
  }

  setupConnection() {
    this.playerEl.playsInline = true;
    this.playerEl.addEventListener(
      "loadedmetadata",
      (event) => {
        this.isConnected = true;
        this.callEventListeners("videoInitialised");
      },
      true
    );
  }

  setupDataChannel = (pc, label, options) => {
    try {
      let datachannel = pc.createDataChannel(label, options);
      datachannel.binaryType = "arraybuffer";

      if (this.printDebugMessages)
        console.log(`Created datachannel (${label})`);

      datachannel.onopen = (e) => {
        if (this.printDebugMessages)
          console.log(`data channel (${label}) connect`);
        this.callEventListeners("dataChannelConnected");
      };

      datachannel.onclose = (e) => {
        if (this.printDebugMessages)
          console.log(`data channel (${label}) closed`);
        this.callEventListeners("dataChannelClosed");
      };

      datachannel.onmessage = (e) => {
        if (this.printDebugMessages)
          console.log(`Got message (${label})`, e.data);

        const readToIntArray = (data) => {
          if (data.arrayBuffer !== undefined) {
            return data.arrayBuffer();
          }

          return new Promise((resolve, reject) => {
            resolve(data);
          });
        };

        readToIntArray(e.data).then((buffer) => {
          const view = new Uint8Array(buffer);

          this.callEventListeners("dataChannelMessage", {
            type: view[0],
            data: view.slice(1),
          });
        });
      };

      return datachannel;
    } catch (e) {
      console.warn("No data channel", e);
      return null;
    }
  };

  handleCandidateFromServer = (iceCandidate) => {
    if (this.printDebugMessages) console.log("ICE candidate: ", iceCandidate);

    let candidate = new RTCIceCandidate(iceCandidate);
    this.pcClient.addIceCandidate(candidate).then((_) => {
      if (this.printDebugMessages)
        console.log("ICE candidate successfully added");
    });
  };

  handleOnAudioTrack = function (audioMediaStream) {
    // do nothing the video has the same media stream as the audio track we have here (they are linked)
    if (this.playerEl.srcObject === audioMediaStream) {
      return;
    }
    // video element has some other media stream that is not associated with this audio track
    else if (
      this.playerEl.srcObject &&
      this.playerEl.srcObject !== audioMediaStream
    ) {
      // create a new audio element
      let audioElem = document.createElement("Audio");
      audioElem.autoplay = true;
      audioElem.srcObject = audioMediaStream;
      audioElem.play();

      console.log("Created new audio element to play separate audio stream.");
    }
  };

  handleOnTrack = (e) => {
    if (this.printDebugMessages) console.log("handleOnTrack", e.streams);

    if (e.track.kind === "audio") {
      this.handleOnAudioTrack(e.streams[0]);
      return;
    } else if (
      e.track.kind === "video" &&
      this.playerEl.srcObject !== e.streams[0]
    ) {
      if (this.printDebugMessages)
        console.log("setting video stream from ontrack");
      this.playerEl.srcObject = e.streams[0];
    }
  };

  handleOnIcecandidate = (e) => {
    if (this.printDebugMessages) console.log("ICE candidate", e);
    if (e.candidate && e.candidate.candidate) {
      this.callEventListeners("webRtcCandidate", e.candidate);
    }
  };

  handleCreateOffer = () => {
    this.pcClient.createOffer(this.sdpConstraints).then(
      (offer) => {
        offer.sdp = offer.sdp.replace(
          "useinbandfec=1",
          "useinbandfec=1;stereo=1;sprop-maxcapturerate=48000;maxaveragebitrate=128000"
        );
        this.pcClient.setLocalDescription(offer);
        // (andriy): increase start bitrate from 300 kbps to 20 mbps and max bitrate from 2.5 mbps to 100 mbps
        // (100 mbps means we don't restrict encoder at all)
        // after we `setLocalDescription` because other browsers are not c happy to see google-specific config
        offer.sdp = offer.sdp.replace(
          /(a=fmtp:\d+ .*level-asymmetry-allowed=.*)\r\n/gm,
          "$1;x-google-start-bitrate=3000;x-google-max-bitrate=20000\r\n"
        );

        this.callEventListeners("webRtcOffer", offer);
      },
      function () {
        console.warn("Couldn't create offer");
      }
    );
  };

  setupPeerConnection = () => {
    //Setup peerConnection events
    this.pcClient.onsignallingstatechange = (e) =>
      this.callEventListeners("onsignallingstatechange", e);
    this.pcClient.oniceconnectionstatechange = (e) =>
      this.callEventListeners("oniceconnectionstatechange", e);
    this.pcClient.onicegatheringstatechange = (e) =>
      this.callEventListeners("onicegatheringstatechange", e);

    this.pcClient.ontrack = this.handleOnTrack;
    this.pcClient.onicecandidate = this.handleOnIcecandidate;
  };

  createOffer = () => {
    if (this.pcClient) {
      console.log("Closing existing PeerConnection");
      this.pcClient.close();
      this.pcClient = null;
    }

    if (this.printDebugMessages) console.log("Set up connection", this.config);

    this.pcClient = new RTCPeerConnection(this.config);

    const setupTransceivers = async () => {
      this.pcClient.addTransceiver("video", { direction: "recvonly" });
      this.pcClient.addTransceiver("audio", { direction: "recvonly" });
    };

    setupTransceivers().finally(() => {
      this.setupPeerConnection(this.pcClient);

      this.dcClient = this.setupDataChannel(
        this.pcClient,
        "cirrus",
        this.dataChannelOptions
      );

      this.handleCreateOffer(this.pcClient);
    });
  };

  //Called externaly when an answer is received from the server
  receiveAnswer = (answer) => {
    if (this.printDebugMessages) console.log("Received answer", answer);
    const answerDesc = new RTCSessionDescription(answer);
    this.pcClient.setRemoteDescription(answerDesc);
  };

  close = () => {
    if (this.pcClient) {
      if (this.printDebugMessages) console.log("Closing existing peerClient");
      this.pcClient.close();
      this.pcClient = null;
    }

    if (this.aggregateStatsIntervalId)
      clearInterval(this.aggregateStatsIntervalId);
  };

  //Sends data across the datachannel
  send = (data, type) => {
    if (this.dcClient && this.dcClient.readyState === "open") {
      this.dcClient.send(data);

      this.callEventListeners("dataSent", type);
    }
  };

  getStats = (onStats) => {
    if (this.pcClient && onStats) {
      this.pcClient.getStats(null).then((stats) => {
        onStats(stats);
      });
    }
  };

  aggregateStats = (checkInterval) => {
    let printAggregatedStats = () => {
      this.getStats(this.generateAggregatedStats);
    };

    console.log("Setting interval: aggregateStatsIntervalId");
    this.aggregateStatsIntervalId = setInterval(
      printAggregatedStats,
      checkInterval
    );
  };

  generateAggregatedStats = (stats) => {
    if (!stats) {
      return;
    }

    let newStat = {};

    stats.forEach((stat) => {
      //                    console.log(JSON.stringify(stat, undefined, 4));
      if (
        stat.type === "inbound-rtp" &&
        !stat.isRemote &&
        (stat.mediaType === "video" || stat.id.toLowerCase().includes("video"))
      ) {
        newStat.timestamp = stat.timestamp;
        newStat.bytesReceived = stat.bytesReceived;
        newStat.framesDecoded = stat.framesDecoded;
        newStat.packetsLost = stat.packetsLost;
        newStat.bytesReceivedStart =
          this.aggregatedStats && this.aggregatedStats.bytesReceivedStart
            ? this.aggregatedStats.bytesReceivedStart
            : stat.bytesReceived;
        newStat.framesDecodedStart =
          this.aggregatedStats && this.aggregatedStats.framesDecodedStart
            ? this.aggregatedStats.framesDecodedStart
            : stat.framesDecoded;
        newStat.timestampStart =
          this.aggregatedStats && this.aggregatedStats.timestampStart
            ? this.aggregatedStats.timestampStart
            : stat.timestamp;

        if (this.aggregatedStats && this.aggregatedStats.timestamp) {
          if (this.aggregatedStats.bytesReceived) {
            // bitrate = bits received since last time / number of ms since last time
            //This is automatically in kbits (where k=1000) since time is in ms and stat we want is in seconds (so a '* 1000' then a '/ 1000' would negate each other)
            newStat.bitrate =
              (8 *
                (newStat.bytesReceived - this.aggregatedStats.bytesReceived)) /
              (newStat.timestamp - this.aggregatedStats.timestamp);
            newStat.bitrate = Math.floor(newStat.bitrate);
            newStat.lowBitrate =
              this.aggregatedStats.lowBitrate &&
              this.aggregatedStats.lowBitrate < newStat.bitrate
                ? this.aggregatedStats.lowBitrate
                : newStat.bitrate;
            newStat.highBitrate =
              this.aggregatedStats.highBitrate &&
              this.aggregatedStats.highBitrate > newStat.bitrate
                ? this.aggregatedStats.highBitrate
                : newStat.bitrate;
          }

          if (this.aggregatedStats.bytesReceivedStart) {
            newStat.avgBitrate =
              (8 *
                (newStat.bytesReceived -
                  this.aggregatedStats.bytesReceivedStart)) /
              (newStat.timestamp - this.aggregatedStats.timestampStart);
            newStat.avgBitrate = Math.floor(newStat.avgBitrate);
          }

          if (this.aggregatedStats.framesDecoded) {
            // framerate = frames decoded since last time / number of seconds since last time
            newStat.framerate =
              (newStat.framesDecoded - this.aggregatedStats.framesDecoded) /
              ((newStat.timestamp - this.aggregatedStats.timestamp) / 1000);
            newStat.framerate = Math.floor(newStat.framerate);
            newStat.lowFramerate =
              this.aggregatedStats.lowFramerate &&
              this.aggregatedStats.lowFramerate < newStat.framerate
                ? this.aggregatedStats.lowFramerate
                : newStat.framerate;
            newStat.highFramerate =
              this.aggregatedStats.highFramerate &&
              this.aggregatedStats.highFramerate > newStat.framerate
                ? this.aggregatedStats.highFramerate
                : newStat.framerate;
          }

          if (this.aggregatedStats.framesDecodedStart) {
            newStat.avgframerate =
              (newStat.framesDecoded -
                this.aggregatedStats.framesDecodedStart) /
              ((newStat.timestamp - this.aggregatedStats.timestampStart) /
                1000);
            newStat.avgframerate = Math.floor(newStat.avgframerate);
          }
        }
      }

      //Read video track stats
      if (
        stat.type === "track" &&
        (stat.trackIdentifier === "video_label" || stat.kind === "video")
      ) {
        newStat.framesDropped = stat.framesDropped;
        newStat.framesReceived = stat.framesReceived;
        newStat.framesDroppedPercentage =
          (stat.framesDropped / stat.framesReceived) * 100;
        newStat.frameHeight = stat.frameHeight;
        newStat.frameWidth = stat.frameWidth;
        newStat.frameHeightStart =
          this.aggregatedStats && this.aggregatedStats.frameHeightStart
            ? this.aggregatedStats.frameHeightStart
            : stat.frameHeight;
        newStat.frameWidthStart =
          this.aggregatedStats && this.aggregatedStats.frameWidthStart
            ? this.aggregatedStats.frameWidthStart
            : stat.frameWidth;
      }

      if (
        stat.type === "candidate-pair" &&
        stat.hasOwnProperty("currentRoundTripTime") &&
        stat.currentRoundTripTime !== 0
      ) {
        newStat.currentRoundTripTime = stat.currentRoundTripTime;
      }
    });

    this.aggregatedStats = newStat;
    this.callEventListeners("aggregatedStats", newStat);
  };
}
