import https from "https";

const downloadFile = (url, fileSizeInBytes, minMbps, maxPing) => {
  let startTime = new Date().getTime(),
    startData,
    dataReceived = 0,
    cancelled = false;

  return new Promise((resolve, _) => {
    const calculateSpeed = () => {
      const endTime = new Date().getTime();
      const duration = (endTime - startData) / 1000;
      // Convert bytes into bits by multiplying with 8
      const bitsLoaded = dataReceived * 8;
      const bps = bitsLoaded / duration;
      const ping = startData - startTime;
      resolve({ bps, ping, cancelled });
    };

    const request = https.get(
      url + `?t=${new Date().getTime()}`,
      (response) => {
        response.once("data", () => {
          startData = new Date().getTime();
        });

        response.on("data", (data) => {
          dataReceived += data.length;
        });

        response.on("end", () => {
          if (!cancelled) {
            calculateSpeed();
          }
        });
      }
    );

    // Cancel request if run for so long that it's below half of the min speed
    const maxTime = (fileSizeInBytes * 8) / (minMbps * 1000 * 1000);

    setTimeout(() => {
      cancelled = true;
      calculateSpeed();
      request.end();
    }, maxTime * 2 * 1000 + maxPing);
  }).catch((error) => {
    throw new Error("Failed download file test: " + error);
  });
};

export const checkDownloadSpeed = async (minMbps, maxPing) => {
  const files = [
    ["512kb", 512 * 1024],
    ["1mb", 1024 * 1024],
    ["3mb", 3 * 1024 * 1024],
  ];

  const baseUrl =
    "https://duvtp3klbff1f.cloudfront.net/resources/download-test-";
  let speed = 0,
    ping = 0;

  for (let file of files) {
    const result = await downloadFile(
      `${baseUrl}${file[0]}`,
      file[1],
      minMbps,
      maxPing
    );

    if (result.cancelled) {
      return { result: false, cancelled: true };
    }

    speed += result.bps;
    ping += result.ping;
  }

  const bps = Math.round(speed / files.length);
  const mbps = bps / 1000 / 1000;
  const avgPing = ping / files.length;

  return {
    result: mbps >= minMbps && avgPing < maxPing,
    cancelled: false,
    mbps,
    avgPing,
  };
};
